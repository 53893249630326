import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import constants from '../constants';
import FilteredClusterCollection from './FilteredClusterCollection';
import InputFilter from './InputFilter';
import CartView from './CartView';
import CollectionItem from './CollectionItem';
import useCollectionStore from '../state/collectionStore';
import useGeneralStore from '../state/generalStore';
import useUserStore from '../state/userStore';
import { useMediaQuery } from 'react-responsive';

const ClusterCollectionView = ({clusterData}) => {
  const cluster=clusterData;
  const { clusterId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get('eventId');
  const eventName = queryParams.get('eventName');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isSetDropdownOpen, setSetDropdownOpen] = useState(false);
  const [linkUses, setLinkUses] = useState(1);

  const {
    personalCollection,
  } = useCollectionStore();
  const {
    cards,
  } = useGeneralStore();
  const {
    user,
    fetchUser,
  } = useUserStore();

  const [joinLink, setJoinLink] = useState(null);
  const [expandedCardIds, setExpandedCardIds] = useState([]);
  const [filteredCollection, setFilteredCollection] = useState([]);
  const [cardInput, setCardInput] = useState('');
  const [parsedCardInput, setParsedCardInput] = useState({ isValid: false, cardNumber: '', selectedSet: '', isFoil: false });
  const [set, setSet] = useState(`${constants.DEFAULT_SET}`);
  const [isCartExpanded, setIsCartExpanded] = useState(false);
  const [isPasteModalVisible, setIsPasteModalVisible] = useState(false);
  const [pastedText, setPastedText] = useState('');
  const [validationError, setValidationError] = useState('');
  const [cardRequests, setCardRequests] = useState({});
  const [isCartView, setIsCartView] = useState(false); // New state to manage the cart view


  
  const sets = [
    { value: 'all', label: 'All Sets' },
    { value: '6', label: 'Set 6' },
    { value: '5', label: 'Set 5' },
    { value: '4', label: 'Set 4' },
    { value: '3', label: 'Set 3' },
    { value: '2', label: 'Set 2' },
    { value: '1', label: 'Set 1' },
  ];

  const toggleSetDropdown = () => {
    setSetDropdownOpen(prev => !prev);
  };
  
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (isCartExpanded) {
      const handleClickOutside = (event) => {
        const cartHeader = document.querySelector('.cart-header');
        const expandedCartContent = document.querySelector('.expanded-cart-content');
        const pasteModalContent = document.querySelector('.paste-modal-content');

        if (
          cartHeader && expandedCartContent &&
          !cartHeader.contains(event.target) &&
          !expandedCartContent.contains(event.target) &&
          (!pasteModalContent || !pasteModalContent.contains(event.target))
        ) {
          setIsCartExpanded(false);
        }
      };

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isCartExpanded]);

  if (!cluster) {
    return <div>Loading...</div>;
  }
  const owner = cluster.owner;
  const collection = cluster.aggregatedCollection;
  const toggleCardDetails = (cardId) => {
    setExpandedCardIds(prev => 
      prev.includes(cardId) ? prev.filter(id => id !== cardId) : [...prev, cardId]
    );
  };
  
  const handleLinkUsesChange = (e) => {
    e.preventDefault();
    setLinkUses(e.target.value);
  };

  const toggleCartView = () => {
    setIsCartView((prev) => !prev);
  };
  const handleRequestChange = (cardId, quantity, cardName, cardSurname, confirmed = true) => {
    setCardRequests(prev => {
      const newRequests = { ...prev };
      newRequests[cardId] = { quantity, cardName, cardSurname };
      quantity == 0 && confirmed && delete newRequests[cardId];
      return newRequests;
    });
  };

  const handleSetChange = (setSelected) => {
    setSet(setSelected);
    setSetDropdownOpen(false);
  };

  const handleGenerateJoinLink = () => {
    axios.post(`${constants.API_BASE_URL}/api/clusters/${clusterId}/generate-join-link`, { uses: linkUses }, { withCredentials: true })
      .then(response => {
	const cluster_name=encodeURIComponent(clusterData.cluster.name);
        setJoinLink(response.data.joinLink+`?cluster=${cluster_name}`);
      })
      .catch(error => {
        console.error('Error generating join link', error);
      });
  };

  const handleCopyToClipboard = () => {
    if (joinLink) {
      // Create a temporary text area element
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = joinLink;

      // Append it to the body
      document.body.appendChild(tempTextArea);

      // Select the text in the text area
      tempTextArea.select();

      try {
	// Copy the text
	document.execCommand('copy');
	alert('Link copied to clipboard!');
      } catch (err) {
	console.error('Failed to copy: ', err);
      }

      // Remove the temporary text area
      document.body.removeChild(tempTextArea);
    }
  };

  const handleModalClick = (e) => {
      if (e.target === e.currentTarget) {
	setIsPasteModalVisible(false);
	setValidationError('');
	setPastedText('');
      }
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const handleConfirmRequests = () => {
    const requests = Object.entries(cardRequests).map(([cardId, { quantity }]) => ({ cardId, quantity }));
    axios.post(`${constants.API_BASE_URL}/api/clusters/${clusterId}/${eventId}/requests`, { requests }, { withCredentials: true })
      .then(response => {
        alert('Requests confirmed');
      })
      .catch(error => {
        console.error('Error confirming requests', error);
      });
  };

  const toggleCartExpansion = () => {
    setIsCartExpanded(true);
  };

  const incrementRequestQuantity = (cardId, cardName, cardSurname) => {
    setCardRequests(prev => {
      const newQuantity = (prev[cardId]?.quantity || 0) + 1;
      handleRequestChange(cardId, newQuantity, cardName, cardSurname);
      return {
        ...prev,
        [cardId]: { ...prev[cardId], quantity: newQuantity, confirmed: true }
      };
    });
  };

  const resetRequestQuantity = (cardId, cardName, cardSurname) => {
    setCardRequests(prev => {
      handleRequestChange(cardId, 0, cardName, cardSurname, false);
      return {
        ...prev,
        [cardId]: { ...prev[cardId], quantity: 0 }
      };
    });
  };

  const removeRequest = (cardId) => {
    setCardRequests(prev => {
      const newRequests = { ...prev };
      delete newRequests[cardId];
      handleRequestChange(cardId, 0, '', '', true);
      return newRequests;
    });
  };

  const handlePasteListClick = () => {
    setIsPasteModalVisible(true);
  };

  const handlePasteInputChange = (e) => {
    setPastedText(e.target.value);
  };

const handlePasteConfirm = () => {
    const lines = pastedText.split('\n');
    const newRequests = {};

    for (const line of lines) {
      // Updated regex to handle optional surname
      const match = line.match(/^(\d+)\s+(.+?)(?:\s+-\s+(.+))?$/);
      if (!match) {
        setValidationError('Invalid format. Each line must be "{quantity} {card_name} - {card_surname}" or "{quantity} {card_name}".');
        return;
      }

      const [, quantity, cardName, cardSurname] = match;
      const cardId = Object.keys(cards).find(
        id => cards[id].card_name === cardName && (!cardSurname || cards[id].card_surname === cardSurname)
      );

      if (!cardId) {
        setValidationError(`Card not found for name: ${cardName}${cardSurname ? ` - ${cardSurname}` : ''}`);
        return;
      }

      newRequests[cardId] = { quantity: parseInt(quantity, 10), cardName, cardSurname: cardSurname || '' };
    }

    setCardRequests(prev => ({ ...prev, ...newRequests }));
    setIsPasteModalVisible(false);
    setValidationError('');
    setPastedText('');
  };

  const handlePasteCancel = () => {
    setIsPasteModalVisible(false);
    setValidationError('');
    setPastedText('');
  };


  const totalRequestedCards = Object.values(cardRequests).reduce((total, { quantity }) => total + quantity, 0);

  return (
    <div className="collection-view">
    {!isCartView ? (
    <>				
      <div className={isMobile ? "cluster-control-panel-column" : "cluster-control-panel"} >
          <div className="add-remove-form">
	      <div className="custom-select">
		<button className="select-button" onClick={toggleSetDropdown}>
		  {sets.find((option) => option.value === set)?.label || "Select Set"}
		</button>
		{isSetDropdownOpen && (
		  <ul className="dropdown-options">
		    {sets.map((option) => (
		      <li
			key={option.value}
			className="dropdown-option"
			onClick={() => handleSetChange(option.value)}
		      >
			{option.label}
		      </li>
		    ))}
		  </ul>
		)}
	      </div>
            <InputFilter
              set={set}
              collection={collection}
              setFilteredCollection={setFilteredCollection}
              cardInput={cardInput}
              setCardInput={setCardInput}
              setParsedCardInput={setParsedCardInput}
	      setInputFocus={()=>{}}
            />
	   {eventName &&(  <div className="requests-cart-button" onClick={toggleCartView}>
              <span>Requests</span>
              {totalRequestedCards > 0 && <span className="cart-badge">{totalRequestedCards}</span>}
            </div>)}
          </div>
      {user.id === owner.id && (
        <div>
	  <label>Join link for </label>
          <input
            type="number"
            value={linkUses}
            onChange={handleLinkUsesChange}
            maxLength="2"
            style={{ width: '40px' }}
            required
          />
	  <label>users </label>
          <button onClick={handleGenerateJoinLink}>Generate</button>
          {joinLink && (
            <div className="join-link-container">
              <input type="text" value={joinLink} readOnly />
              <button className="copy-button" onClick={handleCopyToClipboard}>Copy</button>
            </div>
          )}
        </div>
      )}

      </div>
      <FilteredClusterCollection
        filteredCollection={filteredCollection}
        users={cluster.cluster.Users}
        handleCollectionItemClick={toggleCardDetails}
        handleRequestChange={handleRequestChange}
	cardRequests={cardRequests}
        eventSelected={!!eventId}
      />
     </>					
      ) : (
        <CartView
	   cardRequests={cardRequests}
	   setCardRequests={setCardRequests}
	   toggleCartView={toggleCartView}
	   handleRequestChange={handleRequestChange}
	   clusterId={clusterId}
	   eventId={eventId}
	/>
      )}
      
    </div>
  );
};

export default ClusterCollectionView;

