import create from 'zustand';
import constants from '../constants';
import axios from 'axios';

const useEventsStore = create((set) => ({
	events: [],
        fetchEvents: async (clusterId) => {
          try{
            const response = await axios.get(`${constants.API_BASE_URL}/api/clusters/${clusterId}/events`, { withCredentials: true });
	    set({ events: response.data.events });
          } catch (error) {
            console.error('Error fetching events', error); 
          }
        },
        createEvent: async (clusterId, eventData) => {
          try {
	      const response = await axios.post(`${constants.API_BASE_URL}/api/clusters/${clusterId}/events`, eventData, { withCredentials: true });
	      set((state) => {
		 const updatedEvents = [...state.events, response.data.event];
		 return { events: updatedEvents };
	      });
	  } catch (error) {
	      console.error('Error creating event', error);
	  }
        },
        updateEvent: async (event_id, eventData) => {
	  try {
	    const response = await axios.put(`${constants.API_BASE_URL}/api/events/${event_id}`, eventData, { withCredentials: true });
            const state = useEventsStore.getState();
	    const updatedEvents = state.events.map(event => event.id === event_id ? response.data.event : event);
	    set({ events: updatedEvents });
	  } catch (error) {
	    console.error('Error updating event', error);
	  }
	},
        deleteEvent: async (event_id) => {
	  try {
	    const response = await axios.delete(`${constants.API_BASE_URL}/api/events/${event_id}`, { withCredentials: true });
	    set((state) => {
	      const updatedEvents = state.events.filter(event => event.id !== event_id);
	      return { events: updatedEvents };
	    });
	  } catch (error) {
	    console.error('Error deleting event', error);
	  }
	},
}));

export default useEventsStore;

