import React, { useEffect, useState } from 'react';
import NormalFoilBadges from './NormalFoilBadges';
import useClusterStore from '../state/clusterStore';
import useLendingStore from '../state/lendingsStore';
import useEventRequestsStore from '../state/eventRequestsStore';
import useUserActivityStore from '../state/userActivityStore';
import axios from 'axios';
import constants from '../constants';

const RequestedQuantities = ({
  request_id,
  lending_id,
  card_id,
  quantity,
  normal_lent_byuser=0,
  normal_input,
  foil_input,
  foil_lent_byuser=0,
  normalAvailableQ,
  foilAvailableQ,
  eventId,
  operation='add',
}) => {
  const {
    createOrUpdateLending,
    deleteLending,
  } = useLendingStore();

  const {
    eventRequestQuantitiesInput,
    setEventRequestQuantitiesInput,
  } = useEventRequestsStore();

  const {
    requestQuantityInputs,
    setRequestQuantityInputs,
  } = useUserActivityStore();

  const totalLent = normal_lent_byuser + foil_lent_byuser;
  const input = requestQuantityInputs[request_id];
  const normalInput = input ? input.normal_input : 0;
  const foilInput = input ? input.foil_input : 0;
  const [normalWarning, setNormalWarning] = useState('');
  const [foilWarning, setFoilWarning] = useState('');
  const [totalWarning, setTotalWarning] = useState('');
  const setNormalOnClick = () => {
    const newInput = {
      normal_input: Math.min(normalAvailableQ, quantity - foilInput),
      foil_input: foilInput,
    };
    setRequestQuantityInputs(request_id, newInput);
//    setNormalInput(Math.min(normalAvailableQ, quantity - foilInput));
  };

  const setFoilOnClick = () => {
    const newInput = {
      normal_input: normalInput,
      foil_input: Math.min(foilAvailableQ, quantity - normalInput),
      request_id,
    };
    setRequestQuantityInputs(request_id, newInput);
//    setFoilInput(Math.min(foilAvailableQ, quantity - foilInput));
  };
  
  const getNewInput = (normal, foil) => {
    return {
      normal_input: normal,
      foil_input: foil,
    };
  };

  const handleNormalLendChange = (quantity) => {
    const normalValue = quantity === '' ? '' : Math.max(0, parseInt(quantity) || 0);
    const foilInputQ = parseInt(foilInput) || 0;
    validateInputs(normalValue, foilInputQ);
    setRequestQuantityInputs(request_id, getNewInput(quantity, foilInput));
  };

  const handleFoilLendChange = (quantity) => {
    const foilValue = quantity === '' ? '' : Math.max(0, parseInt(quantity) || 0);
    const normalInputQ = parseInt(normalInput) || 0;
    validateInputs(normalInputQ, foilValue);
    setRequestQuantityInputs(request_id, getNewInput(normalInput, quantity));
  };

  const validateInputs = (normalQ, foilQ ) => {
    let normalWarn = '';
    let foilWarn = '';
    let totalWarn = '';
    foilQ = foilQ || 0;
    normalQ = normalQ || 0;
    if (normalQ > normalAvailableQ) {
      normalWarn = 'Not enough cards to lend.';
    }

    if (foilQ > foilAvailableQ) {
      foilWarn = 'Not enough cards to lend.';
    }

    if (normalQ + foilQ > quantity) {
      totalWarn = 'User only requested ' + quantity + ' cards.';
    }

    setNormalWarning(normalWarn);
    setFoilWarning(foilWarn);
    setTotalWarning(totalWarn);
     
    return normalWarn === '' && foilWarn === '' && totalWarn === '';
  };

  const handleConfirmLending = (normal_quantity, foil_quantity, status = 1, operation) => {
    createOrUpdateLending(request_id, parseInt(normal_quantity), parseInt(foil_quantity), parseInt(status), operation);
    alert('Lending confirmed');
    const reset_input = {
      normal_input: 0,
      foil_input: 0,
    };
    setRequestQuantityInputs(request_id, reset_input);
  };

  return (
   <div className="requested-quantities"> 
       <div className="available-cards">
    <div className="normal-foil-container-lend">
      <div className="badge-with-input">
	<div
	  className="normal-field"
	  onClick={setNormalOnClick}
	>
	  Normal: {normalAvailableQ}
	</div>
	<div className="lend-inputs">
           <span>Lend:</span>
	   <input
	     type="number"
	     min="0"
	     value={normalInput}
	     onChange={(e) => handleNormalLendChange(e.target.value)}
	     className={normalWarning || totalWarning ? 'input-warning' : ''}
	   />
	 {normalWarning && <div className="warning">{normalWarning}</div>}
	</div>
      </div>
      <div className="badge-with-input">
        <div
	  className="foil-field"
	  onClick={setFoilOnClick}
	>
	  Foil: {foilAvailableQ}
	</div>
        <div className="lend-inputs">
           <span>Lend:</span>
	   <input
	     type="number"
	     min="0"
	     value={foilInput}
	     onChange={(e) => handleFoilLendChange(e.target.value)}
	     className={foilWarning || totalWarning ? 'input-warning' : ''}
	   />
	  {foilWarning && <div className="warning">{foilWarning}</div>}
	</div>
      </div>
      <div className="requested-quantities-button">
	  <button
	    onClick={() => handleConfirmLending(
	      normalInput,
	      foilInput,
	      1,
	      operation
	    )}
	  disabled={!!(normalWarning || foilWarning || totalWarning)}
	  >
	    Confirm
	  </button>
	  {totalWarning && <div className="warning">{totalWarning}</div>}
       </div>
    </div>
   </div>
  </div>
  );
}
export default RequestedQuantities;
