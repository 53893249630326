import create from 'zustand';
import constants from '../constants';
import axios from 'axios';

const useUserStore = create((set) => ({
	user: null,
	fetchUser: async () => {
		try {
			const response = await axios.get(`${constants.API_BASE_URL}/auth/current_user`, { withCredentials: true });
			set({ user: response.data });
		} catch (error) {
			console.error('Error fetching user', error);
		}
	},
	clearUser: () => set({ user: null }),
}));

export default useUserStore;

