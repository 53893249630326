// hooks/useAllRequests.js
import { useEffect, useState } from 'react';
import useRequestsStore from '../state/requestsStore';

const useAllRequests = () => {
  const [isAllRequestsLoading, setIsAllRequestsLoading] = useState(true);
  const allRequests = useRequestsStore((state) => state.allRequests);

  useEffect(() => {
    // Fetch all requests if not already loaded
    if (!allRequests) {
      setIsAllRequestsLoading(true);
    } else {
      setIsAllRequestsLoading(false);
    }
  }, [allRequests]);

  return { isAllRequestsLoading, allRequests };
};

export default useAllRequests;

