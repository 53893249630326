import React, { useEffect, useState } from 'react';
import CollectionItem from './CollectionItem';
import RequestedQuantities from './RequestedQuantities';
import useUserActivityStore from '../state/userActivityStore';

const RequestItem = ({ 
  request,
  clusterId,
  eventId,
  total_coll_normal,
  total_coll_foil,
  total_normal_lending,
  total_foil_lending,
}) => {
  const {
    requestQuantityInputs,
  } = useUserActivityStore();
  const request_id = request.request_id;
  const card_id = request.card_id;
  const quantity = request.quantity_requested;
  const pendingQuantity = request.quantity_requested - request.total_normal_lent - request.total_foil_lent;
  const quantityRemaining = request.quantity_requested - request.total_normal_lent - request.total_foil_lent;
  const normalAvailableQ = total_coll_normal - total_normal_lending;
  const foilAvailableQ = total_coll_foil - total_foil_lending;
  const [expandedRequestQuantities, setExpandedRequestQuantities] = useState(false);
  const input = requestQuantityInputs[request_id];
  const normalInput = input ? input.normal_input : 0;
  const foilInput = input ? input.foil_input : 0;
  const isConfirmed = request.normal_lent_byuser == normalInput && request.foil_lent_byuser == foilInput;
  const [confirmedStyle, setConfirmedStyle] = useState(isConfirmed);
  
  useEffect(() => {
  const isConfirmed = request.normal_lent_byuser == normalInput && request.foil_lent_byuser == foilInput;
    setConfirmedStyle(isConfirmed);
  }, [requestQuantityInputs]);
  

  const toggleRequestQuantities = () => {
    setExpandedRequestQuantities(prev => !prev);
  };
 
  const toggleConfirmDisplay = (e) => {
    setExpandedRequestQuantities(prev => !prev);
  }

  const handleConfirmRequest = (e) => {
  }

  return (
    <div className="request-item-container">
        <div className="member-name">
          {request.requester_name} x{pendingQuantity}
        </div>
      <div className="request-item">
	  <div className="coll-item-lending">
		  <CollectionItem
		    card_id={card_id}
		    card_name={request.card_name}
		    card_surname={request.card_surname}
		    set_id={request.set_id}
		    card_number={request.card_number}
		    color_name={request.color_name}
		    rarity_name={request.rarity_name}
		    handleCollectionItemClick={toggleRequestQuantities}
		  />
		{!confirmedStyle ? (<div className="quantities-button" onClick={toggleConfirmDisplay}>
		   N:{normalInput},F:{foilInput}
		</div>) :
	         (<div className="confirmed-quantities">
		   N:{normalInput},F:{foilInput}
		</div>)}
	  </div>
          {expandedRequestQuantities && (
            <div className="requested-quantities-wrapper">
              <RequestedQuantities 
                request_id={request_id} 
	        lending_id={request.lending_id}
                card_id={card_id}
                quantity={pendingQuantity}
	        normal_lent_byuser={request.normal_lent_byuser}
	        foil_lent_byuser={request.foil_lent_byuser}
                total_coll_normal={total_coll_normal}
                total_coll_foil={total_coll_foil}
                clusterId={clusterId}
	        normalAvailableQ={normalAvailableQ}
	        foilAvailableQ={foilAvailableQ}
		operation="add"
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default RequestItem;

