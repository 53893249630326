import React, {useEffect, useState} from 'react';
import { Route, Routes, NavLink, useNavigate, useLocation } from 'react-router-dom';
import './css/styles.css';
import ClustersView from './components/ClustersView';
import ClusterDashboard from './components/ClusterDashboard';
import EventRequestsView from './components/EventRequestsView';
import PersonalDashboard from './components/PersonalDashboard';
import JoinCluster from './components/JoinCluster';
import useUserStore from './state/userStore';
import axios from 'axios';
import useRequestsStore from './state/requestsStore';
import useCollectionStore from './state/collectionStore';
import constants from './constants';
import generalStore from './state/generalStore';

function MobileApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenuList, setShowMenuList] = useState(false); // State to track menu list
  const [showWarning, setShowWarning] = useState(false); // State to track warning

  const {
	fetchAllCards,
  } = generalStore();
  const {
	user,
	fetchUser,
	clearUser,
  } = useUserStore();
  const {
    fetchAllRequests,
  } = useRequestsStore();

  const {
    fetchPersonalCollection,
  } = useCollectionStore();

  useEffect(() => {
    fetchAllCards();
  }, [fetchAllCards]);

  useEffect(() => {
    fetchUser();
    fetchPersonalCollection();
  }, [fetchPersonalCollection, fetchUser]);

  useEffect(() => {
    fetchAllRequests();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('error') === 'unauthorized') {
      setShowWarning(true);
    }
  }, [location]);
  const handleLogin = () => {
    window.location.href = `${constants.API_BASE_URL}/auth/google`;
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${constants.API_BASE_URL}/auth/logout`, { withCredentials: true }).then(() => {
        clearUser();
        navigate('/');
      });
    } catch (error) {
      console.error('Error during logout', error);
    }
  };
  return (
    <div className="App">
      <div className="mobile-content">
        <Routes>
	    <Route path="/" element={<PersonalDashboard />} />
            <Route path="/personal/*" element={<PersonalDashboard />} />
            <Route path="/clusters" element={<ClustersView />} />
            <Route path="/clusters/:clusterId/*" element={<ClusterDashboard />} />
            <Route path="/clusters/join/:token" element={<JoinCluster />} />
	    <Route path="/clusters/:clusterId/events/:eventId/requests" element={<EventRequestsView />} />
        </Routes>
      </div>
      <div className="mobile-footer">
        <NavLink to="/personal" className="mobile-footer-link" activeClassName="active-footer-link">User</NavLink>
        <NavLink to="/clusters" className="mobile-footer-link" activeClassName="active-footer-link">Clusters</NavLink>
        {user ? (
          <button onClick={handleLogout} className="mobile-footer-link">Logout</button>
        ) : (
          <button onClick={handleLogin} className="mobile-footer-link">Login</button>
        )}
      </div>
    </div>
  );
}

export default MobileApp;

