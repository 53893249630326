import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import constants from '../constants';
//import '../css/joinCluster.css';

const JoinCluster = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [joinMessage, setJoinMessage] = useState('');
  const params = new URLSearchParams(location.search);
  const cluster = decodeURIComponent(params.get('cluster'));

   useEffect(() => {
    // Check if the user is authenticated
    axios.get(`${constants.API_BASE_URL}/auth/current_user`, { withCredentials: true })
      .then(response => {
        //if response is empty, user is not authenticated
	if (!response.data) {
	  setIsAuthenticated(false);
	  return;
	}
	setIsAuthenticated(true);
      })
      .catch(error => {
        console.error('Error checking authentication status', error);
        setIsAuthenticated(false);
      });
  }, []);

  const handleJoinCluster = () => {
    if (!isAuthenticated) {
      // Redirect to Google OAuth
      window.location.href = `${constants.API_BASE_URL}/auth/google?redirect=${encodeURIComponent(window.location.href)}`;
      return;
    }
    // User is authenticated, make the API call to join the cluster
    axios.post(`${constants.API_BASE_URL}/api/clusters/join/${token}`, {}, { withCredentials: true })
      .then(response => {
        setJoinMessage('Joined successfully');
        navigate(`/clusters/${response.data.clusterId}`);
      })
      .catch(error => {
        console.error('Error joining cluster:', error.response ? error.response.data.error : error.message);
	const errorMessage = error.response ? error.response.data.error : error.message;
        setJoinMessage(errorMessage);
      });
  };

  return (
    <div className="join-cluster">
      <h2>Join cluster: {cluster}</h2>
      {joinMessage !='' && <div className="warning">{joinMessage}</div>}
      <button onClick={handleJoinCluster}>Join Cluster</button>
    </div>
  );
};

export default JoinCluster;

