import React, {useState} from 'react';
import { Link, Route, Routes, useParams, useLocation } from 'react-router-dom';
import UserCollectionView from './UserCollectionView';
import LendingsView from './LendingsView';
import '../css/dashboard.css';

const PersonalDashboard = () => {
  const [activeLink, setActiveLink] = useState("collection");
  const [showMenuList, setShowMenuList] = useState(false);
  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  const handlePersonalMenuClick = () => {
    setShowMenuList(!showMenuList);
  }

  return (
    <div className="personal-dashboard">
      <div className="nav-link-slider">
        <div className="nav-link-items" style={{gap: "10px"}}>
          <Link
            className={`nav-link-item ${activeLink === 'collection' ? 'active' : ''}`}
            to="/personal/collection"
            onClick={() => handleLinkClick('collection')}
          >
            Collection
          </Link>
          <Link
            className={`nav-link-item ${activeLink === 'lendings' ? 'active' : ''}`}
            to="/personal/lendings"
            onClick={() => handleLinkClick('lendings')}
          >
            Lendings
          </Link>
          {/* Additional links can be added here */}
        </div>
      </div>
      <div className="personal-content">
        <Routes>
          <Route path="" element={<UserCollectionView />} />
          <Route path="collection" element={<UserCollectionView />} />
          <Route path="lendings" element={<LendingsView />} />
        </Routes>
      </div>
    </div>
  );
};

export default PersonalDashboard;

