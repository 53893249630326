import React, { useState } from 'react';
import CollectionItem from './CollectionItem';
import useRequestStore from '../state/requestsStore';

import CancelModalRequest from './CancelModalRequest';

const MyRequestItem = ({ request, lendings }) => {
  const {
    deleteRequest,
  } = useRequestStore();
  const [showCancelModal, setShowCancelModal] = useState(false); 
  const [expandedLendingQuantities, setExpandedLendingQuantities] = useState(false);
  
  const toggleLendingQuantities = () => {
    setExpandedLendingQuantities(prev => !prev);
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };
  const closeModal = () => {
    setShowCancelModal(false);
  }; 
  const confirmCancel = () => {
    deleteRequest(request.request_id);
    closeModal();
  };

  return(
	<div className="request-item-container">
	  <div className="quantity-description">
		x{request.total_normal_lent+request.total_foil_lent}/{request.quantity_requested}
	  </div>
	  <div className="request-item">
		  <CollectionItem
		    card_id={request.card_id}
		    card_name={request.card_name}
		    card_surname={request.card_surname}
		    set_id={request.set_id}
		    card_number={request.card_number}
		    color_name={request.color_name}
		    rarity_name={request.rarity_name}
		    handleCollectionItemClick={toggleLendingQuantities}
		  />
		  {expandedLendingQuantities && (
		    <div className="lending-quantities-wrapper">
	               {lendings.map(lending => (
			 <div key={lending.lending_id}>
			    {lending.lender_name} lends: {lending.normal_lent} normal, {lending.foil_lent} foil
			 </div>
		       ))}
		    </div>
		)}
	  </div>
          <div>
	    <button className="cancel-button" onClick={handleCancel}>Cancel</button>
	  </div>
	    {showCancelModal && (
	    <CancelModalRequest
	      request={request}
	      onClose={closeModal}
	      onConfirm={confirmCancel}
	    />
	  )}
	      </div>
  );
  
}


export default MyRequestItem
