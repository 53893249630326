import React, { useEffect, useState } from 'react';

const NormalFoilBadges = ({ total_normal, total_foil, setNormalOnClick, setFoilOnClick, clickedMessage, submClassName }) => {
  const [normalJustClicked, setNormalJustClicked] = useState(false); 
  const [foilJustClicked, setFoilJustClicked] = useState(false);
  const timeoutDuration = 300;

  useEffect(() => {
    if (normalJustClicked) {
      const timeoutId = setTimeout(() => {
        setNormalJustClicked(false);
      }, timeoutDuration); // Adjust the duration to match the CSS animation

      return () => clearTimeout(timeoutId);
    }
  }, [normalJustClicked]);

  useEffect(() => {
    if (foilJustClicked) {
      const timeoutId = setTimeout(() => {
        setFoilJustClicked(false);
      }, timeoutDuration); // Adjust the duration to match the CSS animation

      return () => clearTimeout(timeoutId);
    }
  }, [foilJustClicked]);

  const normalOnClick = () => {
    setNormalJustClicked(true);
    setNormalOnClick();
  };

  const foilOnClick = () => {
    setFoilJustClicked(true);
    setFoilOnClick();
  }

  return (
    <div className="normal-foil-container" style={{position: 'relative'}}>
      <div
	className="normal-field"
	onClick={normalOnClick}
        style={{position: 'relative'}}
      >
	Normal: {total_normal}
      {normalJustClicked && <div className={submClassName}>{clickedMessage}</div> }
      </div>
      <div
	className="normal-field-small"
	onClick={normalOnClick}
        style={{position: 'relative'}}
      >
	N: {total_normal}
      {normalJustClicked && <div className={submClassName}>{clickedMessage}</div> }
      </div>
      <div
	className="foil-field"
	onClick={foilOnClick}
        style={{position: 'relative'}}
      >
	Foil: {total_foil}
      {foilJustClicked && <div className={submClassName}>{clickedMessage}</div> }
      </div>
      <div
	className="foil-field-small"
	onClick={foilOnClick}
        style={{position: 'relative'}}
      >
	F: {total_foil}
      {foilJustClicked && <div className={submClassName}>{clickedMessage}</div> }
      </div>
    </div>
  );
}

export default NormalFoilBadges;
