import { useEffect, useState } from 'react';
import useCollectionStore from '../state/collectionStore';

const usePersonalCollection = () => {
  const [isPersonalCollectionLoading, setIsPersonalCollectionLoading] = useState(true);
  const personalCollection = useCollectionStore((state) => state.personalCollection);

  useEffect(() => {
    if(!personalCollection || personalCollection.length === 0) {
	setIsPersonalCollectionLoading(true);
        return;
    }
    setIsPersonalCollectionLoading(false);
  }, [personalCollection]);

  return { isPersonalCollectionLoading, personalCollection };
};

export default usePersonalCollection;
