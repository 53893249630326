import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useClusterStore from '../state/clusterStore';
import useRequestsStore from '../state/requestsStore';
import useLendingsStore from '../state/lendingsStore';
import useEventRequestsStore from '../state/eventRequestsStore';
import RequestItem from './RequestItem';
import '../css/eventRequestsView.css'; // Assuming you have a CSS file for styling
import usePersonalCollection from '../hooks/usePersonalCollection';
import useAllRequests from '../hooks/useAllRequests';
import useMyLendingsSummary from '../hooks/useMyLendingsSummary';
import useUserActivityStore from '../state/userActivityStore';

const EventRequestsView = ( { clusterInfo }) => {
  const { clusterId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get('eventId');
  const filterUserId = queryParams.get('user');
  
  const [selectedUser, setSelectedUser] = useState('');
  const [validatedUserId, setValidatedUserId] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchText, setSearchText] = useState('');

  const history = useNavigate();

  const {isPersonalCollectionLoading, personalCollection} = usePersonalCollection();
  const {isMyLendingsSummaryLoading, myLendingsSummary} = useMyLendingsSummary();
  const {isAllRequestsLoading, allRequests} = useAllRequests();
  const {eventRequestQuantitiesInput, setEventRequestQuantitiesInput} = useEventRequestsStore();

  const eventRequests = allRequests.filter(request => { 
                            return request.event_id == eventId &&
			    request.total_normal_lent + request.total_foil_lent < request.quantity_requested &&
			    (!validatedUserId || request.requester_id.toLowerCase() === validatedUserId.toLowerCase())
			    });
  console.log('eventRequests', eventRequests);
  const allUsers = [
    ...clusterInfo.Users.map(user => ({ id: user.id, name: user.name })),
  ];

  const {
    createOrUpdateLending,
  } = useLendingsStore();
 
  const {
	fetchAllRequests,
  } = useRequestsStore();

  const {
    fetchMyLendingsSummary,
  } = useLendingsStore();

  const {
    requestQuantityInputs,
    setRequestQuantityInputs,
  } = useUserActivityStore();

  useEffect(() => {
      fetchAllRequests();
      fetchMyLendingsSummary();
  }, []);

  useEffect(() => {
    if (filterUserId) {
      setValidatedUserId(filterUserId);
      setSelectedUser(allUsers.find(user => user.id === filterUserId).name);
    }
  }, [filterUserId]);

  const handleInputChange = e => {
    const input = e.target.value;
    setSelectedUser(input);

    const filteredSuggestions = allUsers.filter(user =>
      user.name.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filteredSuggestions);

    const userExists = filteredSuggestions.some(
      user => user.name.toLowerCase() === input.toLowerCase()
    );

    if (userExists) {
      setValidatedUserId(userExists.id);
      updateUrl(userExists.id);
    } else {
      setValidatedUserId('');
    }
  };

  const handleSuggestionClick = user => {
    setSelectedUser(user.name);
    setValidatedUserId(user.id);
    updateUrl(user.id);
    setSuggestions([]);
  };

  const updateUrl = userId => {
    const params = new URLSearchParams(location.search);
    params.set('user', userId);
    history({ search: params.toString() });
  };

  const handleSearchClick = () => {
    setIsModalVisible(true);
    setFilteredUsers(allUsers); // Initially show all users
  };

  const handleUserClick = user => {
    handleSuggestionClick(user);
    setIsModalVisible(false);
  };

  const handleSearchTextChange = e => {
    const input = e.target.value;
    setSearchText(input);
    const filtered = allUsers.filter(user =>
      user.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredUsers(filtered);
  };
  
  const handleLendAllClick = () => {
    console.log('eventRequests', eventRequests);
    console.log('myLendingsSummary', myLendingsSummary);

    eventRequests.map(request => {
      const total_coll_normal = personalCollection.find(card => card.card_id === request.card_id).total_normal;
      const total_coll_foil = personalCollection.find(card => card.card_id === request.card_id).total_foil;
      
      
      const max_normal_to_lend = request.quantity_requested - request.total_normal_lent - request.total_foil_lent;
      const normal_input = Math.min(max_normal_to_lend, total_coll_normal);
      const max_foil_to_lend = request.quantity_requested - normal_input;
      const foil_input = Math.min(max_foil_to_lend, total_coll_foil);
      const input = {
        normal_input,
	foil_input,
      };
      setRequestQuantityInputs(request.request_id, input);
    });
  };

  const handleConfirmAllClick = () => {
    eventRequests.forEach(request => {
      const input = requestQuantityInputs[request.request_id];
      createOrUpdateLending(request.request_id, input.normal_input, input.foil_input);
      handleClearAllClick();
    });
    alert('All lendings confirmed');
  };

  const handleClearAllClick = () => {
      eventRequests.forEach(request => {
        const input = {
	  normal_input: 0,
	  foil_input: 0,
	};
	setRequestQuantityInputs(request.request_id, input);
      });
  };

  if (isAllRequestsLoading || isPersonalCollectionLoading || isMyLendingsSummaryLoading ) {
    return <div>Loading...</div>;
  }
  return (
    <div className="event-requests-view">
      <div className="event-operations">
	<div className="user-filter">
	  <input
	    type="text"
	    value={selectedUser}
	    onChange={handleInputChange}
	    placeholder="Filter by user"
	  />
	  <button onClick={handleSearchClick}>Search</button>
	  {suggestions.length > 0 && selectedUser != "" && (
	    <ul className="suggestions">
	      {suggestions.map(user => (
		<li key={user.id} onClick={() => handleSuggestionClick(user)}>
		  {user.name}
		</li>
	      ))}
	    </ul>
	  )}
	</div>
    {/*flex style*/}
    <div style={{display: "flex"}}>
  <button className="lend-button" onClick={handleLendAllClick}>Lend all</button>
  <button className="clear-button" onClick={handleClearAllClick}>Clear all</button>
  <button className="confirm-all-button" onClick={handleConfirmAllClick}>Confirm all</button>
</div>
      </div>
      <ul>
        {eventRequests.map(request => {
	    const total_coll_normal = personalCollection.find(card => card.card_id === request.card_id).total_normal;
	    const total_coll_foil = personalCollection.find(card => card.card_id === request.card_id).total_foil;
	    const lendingExists = myLendingsSummary[request.card_id] && myLendingsSummary[request.card_id][request.event_id];
	    const total_normal_lending = lendingExists ? myLendingsSummary[request.card_id][request.event_id].total_normal_lent : 0;
	    const total_foil_lending = lendingExists ? myLendingsSummary[request.card_id][request.event_id].total_foil_lent : 0;
          return (
	    <RequestItem
		request={request}
		clusterId={clusterId}
		eventId={eventId}
	        total_coll_normal={total_coll_normal}
	        total_coll_foil={total_coll_foil}
	        total_normal_lending={total_normal_lending}
	        total_foil_lending={total_foil_lending}
	    />
          );
        })}
      </ul>
      {/* Modal for selecting users */}
      {isModalVisible && (
        <div className="user-modal">
          <div className="user-modal-content">
            <input
              type="text"
              value={searchText}
              onChange={handleSearchTextChange}
              placeholder="Search users"
            />
            <ul>
              {filteredUsers.map(user => (
                <li key={user.id} onClick={() => handleUserClick(user)}>
                  {user.name}
                </li>
              ))}
            </ul>
            <button onClick={() => setIsModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventRequestsView;

