import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useParams, useLocation, useNavigate } from 'react-router-dom';
import ClusterCollectionView from './ClusterCollectionView';
import EventListView from './EventListView';
import EventRequestsView from './EventRequestsView';
import LendingsView from './LendingsView';
import MyRequestsView from './MyRequestsView';
import axios from 'axios';
import useFetchClusterData from '../hooks/useFetchClusterData';
import useEventsStore from '../state/eventsStore';
import constants from '../constants';
import '../css/dashboard.css';

const ClusterDashboard = () => {
  const { clusterId } = useParams();
  const { clusterData, isLoading, error, isDataDownloaded } = useFetchClusterData(clusterId);
  const location = useLocation();
  const navigate = useNavigate();
  const { events, fetchEvents } = useEventsStore();
  const [activeLink, setActiveLink] = useState('collection');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [eventNameOpen, setEventNameOpen] = useState(false);
  const [eventInfoModalOpen, setEventInfoModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get('eventId');
  const eventName = queryParams.get('eventName') || '';

  const event = events.find(event => event.id === parseInt(eventId));
  useEffect(() => {
    fetchEvents(clusterId);
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/collection')) {
      setActiveLink('collection');
    } else if (location.pathname.includes('/events/requests')) {
      setActiveLink('requests');
    } else if (location.pathname.includes('/events/myrequests')) {
      setActiveLink('my requests');
    } else if (location.pathname.includes('/events/mylendings')) {
      setActiveLink('my lendings');
    } else {
      setActiveLink('collection');
    }
  }, [location.pathname]);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleEventInfoToggle = () => {
    setEventInfoModalOpen(!eventInfoModalOpen);
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    navigate(`/clusters/${clusterId}/collection?eventId=${event.id}&eventName=${encodeURIComponent(event.name)}`);
    setDropdownOpen(false);
  };

  const handleSelectEvent = () => {
    navigate(`/clusters/${clusterId}/events`);
  };

  const handleEventNameToggle = () => {
    setEventNameOpen(!eventNameOpen);
  };

  const handleEventClick = (path) => {
    navigate(path);
    setDropdownOpen(false);
  };
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };
  const formatEventTime = (time) => {
    if (!time) return '';
	const [hours, minutes] = time.split(':');
	return `${hours}:${minutes}`;
  };
  if (isLoading) {
    return <div className="loading-message">Loading cluster, please wait...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!isDataDownloaded()) {
    return <div>No data available</div>;
  }

  return (
    <div className="cluster-dashboard">
      <header className="mobile-header">
	<div className="dropdown-menu">
	  <button className="dropdown-button" onClick={handleDropdownToggle}>
	    {activeLink.charAt(0).toUpperCase() + activeLink.slice(1)}
	    <span className="dropdown-arrow">▼</span>
	  </button>
	  {dropdownOpen && (
	    <div className="dropdown-content">
	      <button
		className={activeLink === 'collection' ? 'active' : ''}
		onClick={() => handleEventClick(`/clusters/${clusterId}/collection?eventId=${eventId}&eventName=${eventName}`, 'collection')}
	      >
		Collection
	      </button>
	     {eventName && (					
	      <>
	      <button
		className={activeLink === 'requests' ? 'active' : ''}
		onClick={() => handleEventClick(`/clusters/${clusterId}/events/requests?eventId=${eventId}&eventName=${eventName}`, 'requests')}
	      >
		Requests
	      </button>
	      <button
		className={activeLink === 'my requests' ? 'active' : ''}
		onClick={() => handleEventClick(`/clusters/${clusterId}/events/myrequests?eventId=${eventId}&eventName=${eventName}`, 'my requests')}
	      >
		My Requests
	      </button>
	      <button
		className={activeLink === 'my lendings' ? 'active' : ''}
		onClick={() => handleEventClick(`/clusters/${clusterId}/events/mylendings?eventId=${eventId}&eventName=${eventName}`, 'my lendings')}
	      >
		My Lendings
	      </button>
	      </>
	     )}
	    </div>
	  )}
	</div>
        <div className="event-name-selector">
          {eventName && (
            <button className="event-info-button" onClick={handleEventInfoToggle}>
              Info
            </button>
          )}
          <button className="event-name-button" onClick={handleSelectEvent}>
            {eventName ? (eventName.length > 10 ? `${eventName.substring(0, 10)}...` : eventName) : "Select Event"}
          </button>
        </div>
      </header>
     {eventInfoModalOpen && eventName && (
        <div className="event-info-modal">
          <div className="event-info-content">
            <h3>Event Information</h3>
            <p><strong>Name:</strong> {eventName}</p>
            <p><strong>Date:</strong> {formatDate(event.date)}</p>
            <p><strong>Time:</strong> {formatEventTime(event.time)}</p>
            <p><strong>Details:</strong> {event.info}</p>
            <button onClick={handleEventInfoToggle} className="close-modal">Close</button>
          </div>
        </div>
      )}
      <div className="cluster-content">
        <Routes>
          <Route path="" element={<ClusterCollectionView eventId={eventId} eventName={eventName} clusterData={clusterData} />} />
          <Route path="collection" element={<ClusterCollectionView eventId={eventId} eventName={eventName} clusterData={clusterData} />} />
          <Route path="events" element={<EventListView />} />
          <Route path="events/requests" element={<EventRequestsView eventId={eventId} eventName={eventName} clusterInfo={clusterData.cluster} />} />
          <Route path="events/myrequests" element={<MyRequestsView eventId={eventId} eventName={eventName} />} />
          <Route path="events/mylendings" element={<LendingsView eventId={eventId} eventName={eventName} />} />
        </Routes>
      </div>
    </div>
  );
};

export default ClusterDashboard;

