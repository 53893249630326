// src/constants.js
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const DEFAULT_SET = process.env.REACT_APP_DEFAULT_SET;

export default {
  API_BASE_URL,
  DEFAULT_SET,
  // Add other constants here
};

