// CartView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollectionItem from './CollectionItem';
import useGeneralStore from '../state/generalStore';
import constants from '../constants';

const CartView = ({ 
     cardRequests, 
     setCardRequests, 
     toggleCartView, 
     handleRequestChange,
     clusterId,
     eventId
  }) => {
  const [pastedText, setPastedText] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isPasteModalVisible, setIsPasteModalVisible] = useState(false);
  const incrementRequestQuantity = (cardId, cardName, cardSurname) => {
    setCardRequests(prev => {
      const newQuantity = (prev[cardId]?.quantity || 0) + 1;
      handleRequestChange(cardId, newQuantity, cardName, cardSurname);
      return {
        ...prev,
        [cardId]: { ...prev[cardId], quantity: newQuantity, confirmed: true }
      };
    });
  };

  const {
    cards,
  } = useGeneralStore();
  const handleConfirmRequests = () => {
    const requests = Object.entries(cardRequests).map(([cardId, { quantity }]) => ({ cardId, quantity }));
    axios.post(`${constants.API_BASE_URL}/api/clusters/${clusterId}/${eventId}/requests`, { requests }, { withCredentials: true })
      .then(response => {
        alert('Requests confirmed');
      })
      .catch(error => {
        console.error('Error confirming requests', error);
      });
  };
  const handlePasteListClick = () => {
    setIsPasteModalVisible(true);
  };
  const handlePasteConfirm = () => {
    const lines = pastedText.split('\n');
    const newRequests = {};

    for (const line of lines) {
      // Updated regex to handle optional surname
      const match = line.match(/^(\d+)\s+(.+?)(?:\s+-\s+(.+))?$/);
      if (!match) {
        setValidationError('Invalid format. Each line must be "{quantity} {card_name} - {card_surname}" or "{quantity} {card_name}".');
        return;
      }

      const [, quantity, cardName, cardSurname] = match;
      const cardId = Object.keys(cards).find(
        id => cards[id].card_name === cardName && (!cardSurname || cards[id].card_surname === cardSurname)
      );

      if (!cardId) {
        setValidationError(`Card not found for name: ${cardName}${cardSurname ? ` - ${cardSurname}` : ''}`);
        return;
      }

      newRequests[cardId] = { quantity: parseInt(quantity, 10), cardName, cardSurname: cardSurname || '' };
    }

    setCardRequests(prev => ({ ...prev, ...newRequests }));
    setIsPasteModalVisible(false);
    setValidationError('');
    setPastedText('');
  };

  const handlePasteCancel = () => {
    setIsPasteModalVisible(false);
    setValidationError('');
    setPastedText('');
  };
  const handlePasteInputChange = (e) => {
    setPastedText(e.target.value);
  };
  const handleModalClick = (e) => {
      if (e.target === e.currentTarget) {
	setIsPasteModalVisible(false);
	setValidationError('');
	setPastedText('');
      }
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const resetRequestQuantity = (cardId, cardName, cardSurname) => {
    setCardRequests(prev => {
      handleRequestChange(cardId, 0, cardName, cardSurname, false);
      return {
        ...prev,
        [cardId]: { ...prev[cardId], quantity: 0 }
      };
    });
  };

  const removeRequest = (cardId) => {
    setCardRequests(prev => {
      const newRequests = { ...prev };
      delete newRequests[cardId];
      handleRequestChange(cardId, 0, '', '', true);
      return newRequests;
    });
  };
  return (
    <div className="cart-view">
      {/* Cart Full View Header with Close Icon */}
      <div className="cart-view-header">
        <span className="close-icon" onClick={toggleCartView}>X</span>
        <h3>Requests Cart</h3>
      </div>
      {/* Cart Content */}
      <div className="cart-content">
	 <span>
          <button className="cart-button" onClick={() => navigator.clipboard.writeText(
            Object.values(cardRequests)
              .map(({ quantity, cardName, cardSurname }) => `${quantity} ${cardName} - ${cardSurname}`)
              .join(', ')
          )}>
            📋 Copy
          </button>
          <button className="cart-button" onClick={handlePasteListClick}>
            📥 Paste
          </button>
          <button className="confirm-cart-button" onClick={handleConfirmRequests}>
            ✔ Confirm
          </button>
	</span>
        <ul>
          {Object.entries(cardRequests).map(([cardId, { quantity, cardName, cardSurname }]) => (
            <li key={cardId}>
              <CollectionItem
		  card_id={cardId}
		  card_name={cards[cardId].card_name}
		  card_surname={cards[cardId].card_surname}
		  set_id={cards[cardId].set_id}
		  card_number={cards[cardId].card_number}
		  color_name={cards[cardId].color_name}
		  rarity_name={cards[cardId].rarity_name}
              />
              {/* Include other controls for managing requests if necessary */}
	      <div className="request-controls">
	      <button className="quantity-button" onClick={() => incrementRequestQuantity(cardId, cardName, cardSurname)}>
		{quantity}
	      </button>
	      {quantity > 0 ? (
		<button className="clear-button" onClick={() => resetRequestQuantity(cardId, cardName, cardSurname)}>Clear</button>
	      ) : (
		<button className="remove-button" onClick={() => removeRequest(cardId)}>Remove</button>
	      )}
	      </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Paste Modal */}
      {isPasteModalVisible && (
        <div className="paste-modal" onClick={handleModalClick}>
          <div className="paste-modal-content" onClick={stopPropagation}>
            <h3>Paste Request List</h3>
            <textarea value={pastedText} onChange={handlePasteInputChange} />
            {validationError && <p className="error-message">{validationError}</p>}
            <button onClick={handlePasteConfirm}>Confirm</button>
            <button onClick={handlePasteCancel}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartView;

