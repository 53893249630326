import React, { useEffect, useState } from 'react';

const InputFilter = ({ 
       set,
       collection,
       filteredCollection,
       setFilteredCollection,
       cardInput,
       setCardInput,
       parsedCardInput,
       setParsedCardInput,
       operation,
       isAddMode,
       onSubmit,
       setInputFocus,
     } ) => {
  const [expandedCardIds, setExpandedCardIds] = useState([]);
  const [isValidCard, setIsValidCard] = useState(true);  
  const [justSubmitted, setJustSubmitted] = useState(false);
  const [submittedMessages, setSubmittedMessages] = useState({});

  const parseCardInput = (input, set) => {
    input = input.trim().toLowerCase();
    let isFoil = false;
    let cardNumber = '';
    let selectedSet = set;
    let isValid = false;
    let cardNameSurname = '';

    // Check for the various formats
    const setCardMatch = input.match(/^(\d+f?)(?:-(\d*))?(?:\s+(.*))?$/);
    if (setCardMatch) {
      cardNumber = setCardMatch[1];
      selectedSet = setCardMatch[2] || set;
      isFoil = cardNumber.endsWith('f');
      if (isFoil) {
	cardNumber = cardNumber.slice(0, -1);
      }
      cardNameSurname = setCardMatch[3] ? setCardMatch[3].trim() : '';
      console.log('cardNameSurname', setCardMatch[3]);
      if (cardNameSurname) {
	// If name and surname are specified, validate the combination
	const exactMatch = collection.find(item => {
          //build a string with collection item cardName Surname but do not include Surname if it is empty
          const itemSurname = item.card_surname ? ` ${item.card_surname.toLowerCase()}` : '';
	  if(cardNumber !== ''){
	    return item.set_id.toString() === selectedSet &&
	      item.card_number.toString() === cardNumber &&
	      (`${item.card_name.toLowerCase()}${itemSurname}` === cardNameSurname)
	  }
	  return item.set_id.toString() === selectedSet &&
	  (`${item.card_name.toLowerCase()}${item.card_surname}` === cardNameSurname)
	});
	isValid = !!exactMatch;
	if (exactMatch) {
	  cardNumber = exactMatch.card_number;
	}
      } else if (selectedSet !== set) {
	// Valid when set number is provided
	isValid = true;
      } else {
	// Direct card number input
	isValid = true;
      }
    } else {
      // Check for a match with card name and surname without set number
      cardNameSurname = input;
      const exactMatch = collection.find(item => {
        const itemSurname = item.card_surname ? ` ${item.card_surname.toLowerCase()}` : ''; 
	return `${item.card_name.toLowerCase()}${itemSurname}` === cardNameSurname
      });
      if (exactMatch) {
	cardNumber = exactMatch.number;
	selectedSet = exactMatch.set;
	isValid = true;
      }
    }
    return { isValid, cardNumber, selectedSet, cardNameSurname, isFoil };
  };

  const updateFilteredCollection = (collection, input, set, parsedResult = null) => {
    if (!parsedResult) {
      parsedResult = parseCardInput(input, set);
    }
    const filtered = collection.filter(item => {
	let isCardMatch = false;
	let isNumberMatch = false;
	let isSetMatch = false;
	const nameSurname = `${item.card_name.toLowerCase()} ${item.card_surname.toLowerCase()}`;
	const isNameSurnameMatch = nameSurname.includes(parsedResult.cardNameSurname);
	if (!parsedResult.cardNumber || parsedResult.cardNumber === '') {
	  isSetMatch = set === 'all' || item.set_id.toString() === set;
	  isCardMatch = isNameSurnameMatch && isSetMatch;
	} else {
	  isNumberMatch = item.card_number.toString() === parsedResult.cardNumber.toString();
	  if(parsedResult.selectedSet !== '' && parsedResult.selectedSet !== 'all'){
	    if(set === 'all'){
	      isSetMatch = item.set_id.toString() === parsedResult.selectedSet.toString();
	    }
	    else{
	      isSetMatch = item.set_id.toString() === set && item.set_id.toString() === parsedResult.selectedSet.toString();
	    }
	  }
	  else{
	    isSetMatch = set === 'all' || item.set_id.toString() === set;
	  }
	  isCardMatch = isNumberMatch && isSetMatch && isNameSurnameMatch;
	}
	return isCardMatch;         
      }
    );
    setFilteredCollection(filtered);
  };

  const validateAndFilterCardInput = (input, set) => {
    const parsedResult = parseCardInput(input, set);
    console.log('parsedResult', parsedResult);
    setIsValidCard(parsedResult.isValid);
    setParsedCardInput(parsedResult); // Store the parsed result
    updateFilteredCollection(collection, input, set, parsedResult);
  };

  useEffect(() => {
    if (justSubmitted) {
      const timeoutId = setTimeout(() => {
        setJustSubmitted(false);
        setSubmittedMessages({}); // Hide the message after animation
      }, 1000); // Adjust the duration to match the CSS animation

      return () => clearTimeout(timeoutId);
    }
    updateFilteredCollection(collection, cardInput, set);
  }, [justSubmitted, set, collection]);

  useEffect(() => {
    validateAndFilterCardInput(cardInput, set);
  }, [cardInput]);


  const toggleCardDetails = (cardId) => {
    setExpandedCardIds(prev => 
      prev.includes(cardId) ? prev.filter(id => id !== cardId) : [...prev, cardId]
    );
  };

  const handleCardInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setCardInput(value);
    validateAndFilterCardInput(value, set);
  }

  const handleCollectionItemClick = (cardNumber, cardSet, cardName, cardSurname, isFoil = false) => {
  };

  const handleClearCardInput = (e) => {
    e.preventDefault();
    setCardInput('');
    setParsedCardInput({ isValid: false, cardNumber: '', selectedSet: '', isFoil: false });
    updateFilteredCollection(collection, '', set); // Update filtered collection with empty input
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setJustSubmitted(true);
    const submittedCard = filteredCollection.find(item => 
         item.card_number.toString() === parsedCardInput.cardNumber.toString() 
	&& item.set_id.toString() === parsedCardInput.selectedSet.toString());
    const foilSuffix = parsedCardInput.isFoil ? 'f' : '';
    const message = `${submittedCard.card_number}${foilSuffix}-${submittedCard.set_id} ${submittedCard.card_name} - ${submittedCard.card_surname}`;
    const truncatedMessage = message.length > 10 ? message.substring(0, 15) + '...' : message;
    
    let playsetMessage="";
    if(submittedCard.total_normal + submittedCard.total_foil === 3){
      playsetMessage = "You got a playset!";
    }
    const operationMessage=isAddMode ? "added" : "removed";
    
    const submittedMessagesObj = {
      operation: `${truncatedMessage} ${operationMessage}!`,
      playset: `${playsetMessage}`,
    };
    setSubmittedMessages(submittedMessagesObj);
    handleClearCardInput(e);
    if(onSubmit){
      onSubmit(e);
    }
  }

  const handleFocus = (e) => {
    e.preventDefault();
    setInputFocus(true);
  }
  
  return (
     <form onSubmit={handleSubmit} className="input-filter-form" style={{ position: 'relative' }}>
      <div className="main-input">
        <input
          type="text"
          placeholder="Card ID or Name"
          value={cardInput}
          onChange={handleCardInputChange}
          maxLength="50"
          required
	  onFocus={handleFocus}
          className="card-input-field"
        />
        {cardInput && (
          <span className="clear-icon" onClick={handleClearCardInput}>
            &times;
          </span>
        )}
	{onSubmit && <button
          type="submit"
          className={`submit-icon-button ${isAddMode ? 'submit-icon-button-add' : 'submit-icon-button-remove'} ${
            !isValidCard || filteredCollection.length === 0 ? 'disabled-button' : ''
          }`}
          disabled={!isValidCard || filteredCollection.length === 0}
        >
	  ⏎
        </button>}
      </div>
	  {justSubmitted && 
	   <div>
	    {isAddMode ? <div className="submitted-added-message">{submittedMessages.operation}</div>
                      :
                  <div className="submitted-removed-message">{submittedMessages.operation}</div> 
            }
	    {submittedMessages.playset != "" && <div className="submitted-playset-message">{submittedMessages.playset}</div>}
	   </div>
	  }
      </form>
  );
};

export default InputFilter;

