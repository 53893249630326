import create from 'zustand';
import constants from '../constants';
import axios from 'axios';

const useGeneralStore = create((set) => ({
  cards: {},
  defaultState: 5,
  fetchAllCards: async () => { 
    try {
	const response = await axios.get(`${constants.API_BASE_URL}/api/cards`, { withCredentials: false });
	set({ cards: response.data });
    }
    catch (error) {
        console.error('Error fetching cards', error); 
    }
  }
  
}));

export default useGeneralStore;

