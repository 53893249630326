import React, { useState } from 'react';
import CollectionItem from './CollectionItem';
import RequestedQuantities from './RequestedQuantities';
import useCollectionStore from '../state/collectionStore';
import useLendingsStore from '../state/lendingsStore';
import CancelModal from './CancelModal';
import '../css/lendingItem.css' 

const LendingItem = ({ 
  request,
  total_coll_normal,
  total_coll_foil, 
  total_normal_lending,
  total_foil_lending
}) => {
  const {
    deleteLending
  } = useLendingsStore();
  const request_id = request.request_id;
  const card_id = request.card_id;
  const quantity = request.quantity_requested;
  const normalQuantityLentByUser = request.normal_lent_byuser;
  const foilQuantityLentByUser = request.foil_lent_byuser;
  const totalQuantityLentByUser = normalQuantityLentByUser + foilQuantityLentByUser;
  const totalQuantityLent = request.total_normal_lent + request.total_foil_lent;
  const normalAvailableQ = total_coll_normal - total_normal_lending;
  const foilAvailableQ = total_coll_foil - total_foil_lending;
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [expandedRequestQuantities, setExpandedRequestQuantities] = useState(false);

  const toggleRequestQuantities = () => {
    setExpandedRequestQuantities(prev => !prev);
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const handleModify = () => {
    setExpandedRequestQuantities(prev => !prev);
  };

  const closeModal = () => {
    setShowCancelModal(false);
  };

  const confirmCancel = () => {
    deleteLending(request.lending_id);
    closeModal();
  };

  return (
    <div className="request-item-container">
      <div className="lendings-description">
         <div className="lendings-member-name"> To {request.requester_name}: {totalQuantityLentByUser} out of {totalQuantityLent}/{quantity} </div>
	 <div className="lendings-where">{request.cluster_name} - {request.event_name}</div>
      </div>
      <div className="request-item">
          <CollectionItem
            card_id={card_id}
            card_name={request.card_name}
            card_surname={request.card_surname}
            set_id={request.set_id}
            card_number={request.card_number}
            color_name={request.color_name}
            rarity_name={request.rarity_name}
            total_normal={total_coll_normal}
            total_foil={total_coll_foil}
            handleCollectionItemClick={toggleRequestQuantities}
          />
          {expandedRequestQuantities && (
            <div className="requested-quantities-wrapper">
              <RequestedQuantities 
                request_id={request_id} 
	        lending_id={request.lending_id}
                card_id={card_id}
                quantity={quantity}
		normal_lent_byuser={request.normal_lent_byuser}
		foil_lent_byuser={request.foil_lent_byuser}
                total_coll_normal={total_coll_normal}
                total_coll_foil={total_coll_foil}
                clusterId={request.cluster_id}
                eventId={request.event_id}
	        normalAvailableQ={normalAvailableQ}
	        foilAvailableQ={foilAvailableQ}
		operation="update"
              />
            </div>
          )}
      </div>
      <div>
	<button className="cancel-button" onClick={handleCancel}>Cancel</button>
      </div>
        {showCancelModal && (
        <CancelModal
          lending={request}
          onClose={closeModal}
          onConfirm={confirmCancel}
        />
      )}
    </div>
  );
};

export default LendingItem;

