import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLendingsStore from '../state/lendingsStore';
import useRequestsStore from '../state/requestsStore';
import useCollectionStore from '../state/collectionStore';
import LendingItem from './LendingItem';
import '../css/lendingsView.css'; // Assuming you have a CSS file for styling
import usePersonalCollection from '../hooks/usePersonalCollection';
import useAllRequests from '../hooks/useAllRequests';
import useMyLendingsSummary from '../hooks/useMyLendingsSummary';

const LendingsView = () => {
  const {isPersonalCollectionLoading, personalCollection} = usePersonalCollection();
  const {isAllRequestsLoading, allRequests} = useAllRequests();
  const {isMyLendingsSummaryLoading, myLendingsSummary} = useMyLendingsSummary();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get('eventId');
  const {
//    allRequests,
    fetchAllRequests,
  } = useRequestsStore();
  const {
//    personalCollection,
  } = useCollectionStore();
  const {
     fetchMyLendingsSummary,
  } = useLendingsStore();
  //we store the requests where the user is one of the lenders
  const [userRequests, setUserRequests] = useState([]);

  useEffect(() => {
	fetchAllRequests();
        fetchMyLendingsSummary();
  }, []);

  useEffect(() => {
    const uReq= !eventId ? allRequests.filter(request => request.normal_lent_byuser > 0 || request.foil_lent_byuser > 0)
		      : allRequests.filter(request => request.event_id == eventId && request.normal_lent_byuser > 0 || request.foil_lent_byuser > 0 );
    console.log('uReq', uReq);
    setUserRequests(uReq);
  }, [allRequests]);

  if (isAllRequestsLoading || isPersonalCollectionLoading || isMyLendingsSummaryLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="lendings-view">
      <ul>
        {userRequests.map(request => {
	    const total_coll_normal = personalCollection.find(card => card.card_id === request.card_id).total_normal;
	    const total_coll_foil = personalCollection.find(card => card.card_id === request.card_id).total_foil;
	    const lendingExists = myLendingsSummary[request.card_id] && myLendingsSummary[request.card_id][request.event_id];
	    const total_normal_lending = lendingExists ? myLendingsSummary[request.card_id][request.event_id].total_normal_lent : 0;
	    const total_foil_lending = lendingExists ? myLendingsSummary[request.card_id][request.event_id].total_foil_lent : 0;
	    return (
	       <LendingItem key={request.lending_id}
		  request={request}
		  total_coll_normal={total_coll_normal}
		  total_coll_foil={total_coll_foil}
	          total_normal_lending={total_normal_lending}
	          total_foil_lending={total_foil_lending}
	      />
	    );
          }
	)}
      </ul>
    </div>
  );
};

export default LendingsView;

