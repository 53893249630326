import React from 'react';
import '../css/cancelModal.css'; // Ensure you have appropriate styles

const CancelModalRequest = ({ request, onClose, onConfirm }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Confirm Cancellation</h2>
        <p>Are you sure you want to cancel this request?</p>
        <p>{request.quantity_requested} of {request.card_name} {request.card_surname}</p>
        <button className="modal-button" onClick={onConfirm}>Yes, Cancel</button>
        <button className="modal-button" onClick={onClose}>No, Go Back</button>
      </div>
    </div>
  );
};

export default CancelModalRequest;

