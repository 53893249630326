import create from 'zustand';
import axios from 'axios';
import constants from '../constants';
import { devtools } from 'zustand/middleware';

const useCollectionStore = create(
  devtools(
    set => ({
        personalCollection: [],
	history: [],
        fetchPersonalCollection: async () => {
	    try {
		const response = await axios.get(`${constants.API_BASE_URL}/api/collection`, { withCredentials: true });
		set({ personalCollection: response.data });
	    } catch (error) {
		console.error('Error fetching personal collection', error);
	    }
	  },
        fetchHistory: async () => {
            try {
	       const response = await axios.get(`${constants.API_BASE_URL}/api/collection/history`, { withCredentials: true });
	       set({ history: response.data });
	    } catch (error) {
	       console.error('Error fetching collection history', error);
	    }
        },
        modifyCopiesInCollection: async (cardNumber, selectedSet, isFoil, operation, quantity) => {
	    try {
	       const response = await axios.put(`${constants.API_BASE_URL}/api/collection/modify`, {
	                                  set: selectedSet,
					  cardNumber,
					  isFoil,
					  operation,
					  quantity: parseInt(quantity, 10)
				       }, { withCredentials: true });
	       const updatedItem = response.data.collection;
	       const thisState = useCollectionStore.getState();
	       thisState.updatePersonalCollection(updatedItem.card, updatedItem.normal, updatedItem.foil);
               let newHistory = thisState.history.slice();
	       if (response.data.activity.removeOldest) {
		 newHistory = thisState.history.slice(0, -1);
	       }
	       newHistory.unshift(response.data.activity.details);
	       set({ history: newHistory });
	    } catch (error) {
	       console.error('Error updating card in collection', error);
	    }
	},
	updatePersonalCollection: (cardId, normalQuantity, foilQuantity) => set((state) => {
	    const updatedCollection = [...state.personalCollection];
	    const cardIndex = updatedCollection.findIndex(card => card.card_id === cardId);
	    if (cardIndex === -1) {
			    return state;
	    }
	    updatedCollection[cardIndex].total_normal = normalQuantity;
	    updatedCollection[cardIndex].total_foil = foilQuantity;
	    return { personalCollection: updatedCollection };
	}),
    })
  )
);

export default useCollectionStore;
