import React, { useEffect, useState } from 'react';
import NormalFoilBadges from './NormalFoilBadges';

const CollectionItem = ({ card_id, card_name, card_surname, set_id, card_number, color_name, rarity_name, handleCollectionItemClick }) => {
  const isClickable = handleCollectionItemClick !== undefined;
  const cardSurname = card_surname ? ` - ${card_surname}` : '';
  return (
	<li className="collection-item">
	  <span
	    className={`color-square ${color_name.toLowerCase()}`}
	  ></span>
	  <span
	    className={`rarity-badge ${rarity_name.toLowerCase().replace(' ', '-')}`}
	  >
	    {rarity_name === 'Legendary' && 'LEG'}
	    {rarity_name === 'Super Rare' && 'SR'}
	    {rarity_name === 'Rare' && 'R'}
	    {rarity_name === 'Uncommon' && 'U'}
	    {rarity_name === 'Common' && 'C'}
	    {rarity_name === 'Enchanted' && 'ENC'}
	  </span>
	  <span className="card-info">
	    <span
	      className={isClickable ? "clickable-text" : ""}
	      onClick={() => handleCollectionItemClick(card_id, card_number, set_id, card_name, card_surname)}
	    >
	      {`${card_number}-${set_id} ${card_name} ${cardSurname}`}
	    </span>
	  </span>
	</li>
    );
};

export default CollectionItem;
