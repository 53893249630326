import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './css/styles.css'; // Import the main CSS file
import './css/header.css'; // Import the header CSS file
import './css/mobilestyles.css'; // Import the mobile CSS file
import ClustersView from './components/ClustersView';
import ClusterDashboard from './components/ClusterDashboard';
import EventRequestsView from './components/EventRequestsView';
import PersonalDashboard from './components/PersonalDashboard';
import JoinCluster from './components/JoinCluster';
import useRequestsStore from './state/requestsStore';
import useCollectionStore from './state/collectionStore';
import useUserStore from './state/userStore';
import constants from './constants';
import generalStore from './state/generalStore';
import { useMediaQuery } from 'react-responsive';
import DesktopApp from './DesktopApp';
import MobileApp from './MobileApp';

function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return isMobile ? <MobileApp /> : <DesktopApp />;
}

export default App;

