import create from 'zustand';
import axios from 'axios';
import constants from '../constants';

import useRequestsStore from './requestsStore';
import useClusterStore from './clusterStore';

const useLendingsStore = create((set) => ({
  personalFlatLendings: [],
  myLendingsSummary: {},
  fetchMyLendingsSummary: async () => {
    try {
	const response = await axios.get(`${constants.API_BASE_URL}/api/lendings/my_summary`, { withCredentials: true });
	set({ myLendingsSummary: response.data });
    } catch (error) {
	console.error('Error fetching my lendings summary', error);
    }
  },
  fetchPersonalFlatLendings: async () => {
    try {
      const response = await axios.get(`${constants.API_BASE_URL}/api/lendings/my-flat`, { withCredentials: true });
      set({ personalFlatLendings: response.data.lendings });
    } catch (error) {
      console.error('Error fetching personal lendings', error);
    }
  },
  updateMyLendingsSummary: (cardId, eventId, normalQ, foilQ, prevNormalQ, prevFoilQ) => set((state) => {
    const lendingExists = state.myLendingsSummary[cardId] && state.myLendingsSummary[cardId][eventId];
    const prevNormalTotalLentQ = lendingExists ? state.myLendingsSummary[cardId][eventId].total_normal_lent : 0;
    const prevFoilTotalLentQ = lendingExists ? state.myLendingsSummary[cardId][eventId].total_foil_lent : 0;
    const q_obj = {
      total_normal_lent: prevNormalTotalLentQ - prevNormalQ + normalQ,
      total_foil_lent: prevFoilTotalLentQ - prevFoilQ + foilQ,
    };
    if (!state.myLendingsSummary[cardId]) {
      return {
	myLendingsSummary: {
	  ...state.myLendingsSummary,
	  [cardId]: {
	    [eventId]: q_obj,
	  },
	},
      };
    }
    if (!state.myLendingsSummary[cardId][eventId]) {
      return {
        myLendingsSummary: {
	  ...state.myLendingsSummary,
	  [cardId]: {
	    ...state.myLendingsSummary[cardId],
	    [eventId]: q_obj,
	  },
	},
      };
    }

    const updatedSummary = { 
      ...state.myLendingsSummary,
      [cardId]: {
        ...state.myLendingsSummary[cardId],
	[eventId]: {
	  ...state.myLendingsSummary[cardId][eventId],
	  total_normal_lent: prevNormalTotalLentQ - prevNormalQ + normalQ,
	  total_foil_lent: prevFoilTotalLentQ - prevFoilQ + foilQ,
	},
      },	
    };
    return { myLendingsSummary: updatedSummary };
  }),
  deleteLending: async (lending_id) => {
    try {
      await axios.delete(`${constants.API_BASE_URL}/api/lendings/${lending_id}`, { withCredentials: true });
      let request_to_update=useRequestsStore.getState().allRequests.find(request => request.lending_id == lending_id);
      request_to_update.lending_id=null;
      request_to_update.lending_status=null;
      request_to_update.normal_lent_byuser=0;
      request_to_update.foil_lent_byuser=0;
      const updatedRequests = useRequestsStore.getState().allRequests.map(request => request.lending_id === lending_id ? request_to_update : request);
      useRequestsStore.setState({ allRequests: updatedRequests });
      useLendingsStore.getState().fetchPersonalFlatLendings();
    } catch (error) {
      console.error('Error deleting lending', error);
    }
  },
  createOrUpdateLending: async (request_id, normal_quantity, foil_quantity, status = 1, operation='add') => { 
    
    try {
	  const lending_id=useRequestsStore.getState().allRequests.find(request => request.request_id == request_id).lending_id;
	  const pre_total_normal_lent=useRequestsStore.getState().allRequests.find(request => request.request_id == request_id).total_normal_lent;
	  const pre_total_foil_lent=useRequestsStore.getState().allRequests.find(request => request.request_id == request_id).total_foil_lent;
	  const pre_normal_lent_byuser=useRequestsStore.getState().allRequests.find(request => request.request_id == request_id).normal_lent_byuser;
	  const pre_foil_lent_byuser=useRequestsStore.getState().allRequests.find(request => request.request_id == request_id).foil_lent_byuser;
	  let request_to_update=useRequestsStore.getState().allRequests.find(request => request.request_id == request_id);
	  useLendingsStore.getState().updateMyLendingsSummary(request_to_update.card_id,request_to_update.event_id,normal_quantity,foil_quantity,pre_normal_lent_byuser,pre_foil_lent_byuser);
	  if(normal_quantity==0 && foil_quantity==0 && lending_id!=null){
	    useLendingsStore.getState().deleteLending(lending_id);
	    return;
	  }
	  axios.post(`${constants.API_BASE_URL}/api/lendings`, {
		  eventRequestId: request_id,
		  normal_quantity,
		  foil_quantity,
		  status,
		  operation
	  }, { withCredentials: true })
	  .then(response => {
	    request_to_update.lending_id=response.data.lending.id;
	    request_to_update.lender_id=response.data.lending.lender;
	    request_to_update.lending_status=response.data.lending.status;
	    if(operation=='add'){
	      request_to_update.total_normal_lent=pre_total_normal_lent+normal_quantity;
	      request_to_update.total_foil_lent=pre_total_foil_lent+foil_quantity;
	      request_to_update.normal_lent_byuser=pre_normal_lent_byuser+normal_quantity;
	      request_to_update.foil_lent_byuser=pre_foil_lent_byuser+foil_quantity;
	    } else if(operation=='update'){
	      request_to_update.normal_lent_byuser=normal_quantity;
	      request_to_update.foil_lent_byuser=foil_quantity;
	      request_to_update.total_normal_lent=pre_total_normal_lent-pre_normal_lent_byuser+normal_quantity;
	      request_to_update.total_foil_lent=pre_total_foil_lent-pre_foil_lent_byuser+foil_quantity;
	    }

	    request_to_update.status=status;
	    useRequestsStore.getState().setRequest(request_to_update);
	  })
	  .catch(error => {
	    console.error('Error creating lending', error);
	  });
    } catch (error) {
	console.error('Error creating lending', error);
    }
  }
}));

export default useLendingsStore;

