import React, { useState } from 'react';
import CollectionItem from './CollectionItem';
import NormalFoilBadges from './NormalFoilBadges';
import {useMediaQuery} from 'react-responsive';
import useGeneralStore from '../state/generalStore';

const FilteredClusterCollection = ({ filteredCollection, users, handleRequestChange, cardRequests, eventSelected }) => {
  const [expandedCardIds, setExpandedCardIds] = useState([]);
  const [activeRequests, setActiveRequests] = useState({});
  const [requestQuantities, setRequestQuantities] = useState({});
  const [hideButton, setHideButton] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const toggleCardDetails = (cardId) => {
    setExpandedCardIds(prev => 
      prev.includes(cardId) ? prev.filter(id => id !== cardId) : [...prev, cardId]
    );
  };

  const toggleRequestField = (cardId) => {
    setActiveRequests(prev => ({
      ...prev,
      [cardId]: !prev[cardId]
    }));
  };

  const incrementRequestQuantity = (cardId, cardName, cardSurname) => {
    const newQuantity = cardRequests[cardId] ? cardRequests[cardId].quantity + 1 : 1;
    handleRequestChange(cardId, newQuantity, cardName, cardSurname);
  };

  const resetRequestQuantity = (cardId, cardName, cardSurname) => {
    handleRequestChange(cardId, 0, cardName, cardSurname);
    toggleRequestField(cardId);
  };

  return (
    <div className="collection-view">
      <ul>
        {filteredCollection.map(item => (
          <span key={item.card_id}>
            <div className="collection-item-container">
              <CollectionItem
                card_id={item.card_id}
                card_name={item.card_name}
                card_surname={item.card_surname}
                set_id={item.set_id}
                card_number={item.card_number}
                color_name={item.color_name}
                rarity_name={item.rarity_name}
                handleCollectionItemClick={toggleCardDetails}
              />
	   <div className={`info-container ${isMobile ? 'info-container-mobile' : ''}`}>
              <NormalFoilBadges
                total_normal={item.total_normal}
                total_foil={item.total_foil}
                setNormalOnClick={() => {}}
                setFoilOnClick={() => {}}
              />
              {eventSelected && (
                <div className="request-lend">
                  {activeRequests[item.card_id] ? (
                    <div className="request-controls">
                      <button className="quantity-button" onClick={() => incrementRequestQuantity(item.card_id, item.card_name, item.card_surname)}>
                        {cardRequests[item.card_id] ? cardRequests[item.card_id].quantity : 0}
                      </button>
                      {activeRequests[item.card_id] && (
                        <button className="clear-button" onClick={() => resetRequestQuantity(item.card_id, item.card_name, item.card_surname)}>Clear</button>
                      ) 
			}
                    </div>
                  ) : (
                    <div className="request-controls">
			<button className="request-button" onClick={() => toggleRequestField(item.card_id)}>
			  Request
			</button>
		    </div>
                  )}
                </div>
              )}
	      </div>
            </div>
            {expandedCardIds.includes(item.card_id) && (
              <div className="card-details">
                {users.map(user => (
                  <div key={user.id} className="user-card-details">
                    <span>{user.name}: Normal - {item[`normal_${user.id}`]}, Foil - {item[`foil_${user.id}`]}</span>
                  </div>
                ))}
              </div>
            )}
          </span>
        ))}
      </ul>
    </div>
  );
};

export default FilteredClusterCollection;

