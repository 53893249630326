import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import constants from '../constants';
import useEventsStore from '../state/eventsStore';
import '../css/eventsView.css'; // Import the new CSS file for EventsView

const EventListView = () => {
  const navigate = useNavigate();
  const { clusterId } = useParams();
  const { 
    events,
    fetchEvents,
    createEvent,
    updateEvent,
    deleteEvent,
  } = useEventsStore();
  const [newEvent, setNewEvent] = useState({ name: '', date: new Date(), time: new Date(), info: '' });
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [nameError, setNameError] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const formatEventTime = (time) => {
    if (!time) return '';
	const [hours, minutes] = time.split(':');
	return `${hours}:${minutes}`;
  };
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  const parseEventDateTime = (event) => {
      return new Date(`${event.date.split('T')[0]}T${event.time}`);
    };
  const sortEvents = (events) => {
    const now = new Date();


    const futureEvents = events.filter(event => parseEventDateTime(event) >= now);
    const pastEvents = events.filter(event => parseEventDateTime(event) < now);

    // Sort future events by date and time (closest first)
    futureEvents.sort((a, b) => parseEventDateTime(a) - parseEventDateTime(b));

    // Sort past events by date and time (closest last)
    pastEvents.sort((a, b) => parseEventDateTime(b) - parseEventDateTime(a));

    // Combine future events first, then past events
    return [...futureEvents, ...pastEvents];
  };
  const sortedEvents = sortEvents(events);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const valueUpper = value.charAt(0).toUpperCase() + value.slice(1);
    setNewEvent({ ...newEvent, [name]: valueUpper });

    // Validate the event name
    if (name === 'name' && value.trim() === '') {
      setNameError('Event name is required.');
    } else {
      setNameError('');
    }
  };

  const handleDateChange = (date) => {
    setNewEvent({ ...newEvent, date });
  };

  const handleTimeChange = (time) => {
    setNewEvent({ ...newEvent, time });
  };

  const handleCreateOrUpdateEvent = async () => {
    if (newEvent.name.trim() === '') {
      setNameError('Event name is required.');
      return;
    }

    const formattedDate = newEvent.date.toISOString().split('T')[0];
    const formattedTime = newEvent.time.toTimeString().split(' ')[0];
    const eventData = { ...newEvent, date: formattedDate, time: formattedTime };

    if (isEditing) {
      // Update existing event
      updateEvent(isEditing, eventData);
      setIsEditing(null);
      setNewEvent({ name: '', date: new Date(), time: new Date(), info: '' });
    } else {
      createEvent(clusterId, eventData);
      setIsCreating(false);
      setNewEvent({ name: '', date: new Date(), time: new Date(), info: '' });
    }
  };

  const handleSelectEvent = (event) => {
    navigate(`/clusters/${clusterId}/collection?eventId=${event.id}&eventName=${encodeURIComponent(event.name)}`);
  };

  const startCreatingEvent = (e) => {
    e.stopPropagation(); // Prevent triggering parent click events
    setIsCreating(true);
  };

const startEditingEvent = (event, e) => {
  e.stopPropagation(); // Prevent triggering parent click events
  setIsEditing(event.id);
  
  // Ensure the event.time is valid before creating a Date object
  const eventTime = event.time ? `1970-01-01T${event.time}Z` : null;
  
  setNewEvent({
    name: event.name,
    date: new Date(event.date),
    time: eventTime ? new Date(eventTime) : new Date(), // Fallback to current time if invalid
    info: event.info,
  });
};

  const cancelCreatingOrEditingEvent = () => {
    setIsCreating(false);
    setIsEditing(null);
    setNewEvent({ name: '', date: new Date(), time: new Date(), info: '' });
  };

  const confirmDeleteEvent = (eventId) => {
    setEventToDelete(eventId);
    setShowConfirmModal(true);
  };

  const handleDeleteEvent = () => {
      deleteEvent(eventToDelete);
      setShowConfirmModal(false);
      setEventToDelete(null);
  };

  const cancelDeleteEvent = () => {
    setShowConfirmModal(false);
    setEventToDelete(null);
  };

  return (
    <div className="events-view">
      <div className="events-grid">
        {isCreating || isEditing ? (
          <div className="create-event-form-wrapper">
            <div className="create-event-form">
              <input
                type="text"
                name="name"
                value={newEvent.name}
                onChange={handleInputChange}
                placeholder="Event name"
                className={nameError ? 'input-error' : ''}
              />
              {nameError && <p className="error-message">{nameError}</p>}
              <label htmlFor="date">Date</label>
              <DatePicker
                selected={newEvent.date}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                placeholderText="Date"
              />
              <label htmlFor="time">Time</label>
              <DatePicker
                selected={newEvent.time}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                placeholderText="Time"
              />
              <textarea
                name="info"
                value={newEvent.info}
                onChange={handleInputChange}
                placeholder="Additional info"
              ></textarea>
              <div className="form-buttons">
                <button onClick={handleCreateOrUpdateEvent} disabled={nameError}>
                  {isEditing ? 'Update' : 'Create'}
                </button>
                <button onClick={cancelCreatingOrEditingEvent} className="cancel-button">Cancel</button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="event-frame create-event" onClick={startCreatingEvent}>
              <div className="create-event-placeholder">+</div>
            </div>
            {sortedEvents.map(event => {
               const isPastEvent = parseEventDateTime(event) < new Date();
              return(
		    <div
		      key={event.id}
		      className={`event-frame ${isPastEvent ? 'past-event' : ''}`}
		    >
			<div className="delete-icon" onClick={() => confirmDeleteEvent(event.id)}>×</div>
			<div className="edit-icon" onClick={(e) => startEditingEvent(event, e)}>✎</div>
		    <div onClick={() => handleSelectEvent(event)}>
		      <div className="event-name">{event.name}</div>
		      <div className="event-date-time">{formatDate(event.date)} {formatEventTime(event.time)}</div>
		    </div>
		  </div>
	      )
	    })}
          </>
        )}
      </div>

      {showConfirmModal && (
        <div className="confirm-modal">
          <div className="confirm-modal-content">
            <p>Are you sure you want to delete this event?</p>
            <div className="modal-buttons">
              <button onClick={handleDeleteEvent}>Yes</button>
              <button onClick={cancelDeleteEvent} className="cancel-button">No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventListView;

