import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './css/styles.css'; // Import the main CSS file
import './css/header.css'; // Import the header CSS file
import ClustersView from './components/ClustersView';
import ClusterDashboard from './components/ClusterDashboard';
import EventRequestsView from './components/EventRequestsView';
import PersonalDashboard from './components/PersonalDashboard';
import JoinCluster from './components/JoinCluster';
import useRequestsStore from './state/requestsStore';
import useCollectionStore from './state/collectionStore';
import useUserStore from './state/userStore';
import constants from './constants';
import generalStore from './state/generalStore';

function DesktopApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenuList, setShowMenuList] = useState(false); // State to track menu list
  const [showWarning, setShowWarning] = useState(false); // State to track warning

  const {
	fetchAllCards,
  } = generalStore();
  const {
	user,
	fetchUser,
	clearUser,
  } = useUserStore();
  const {
    fetchAllRequests,
  } = useRequestsStore();

  const {
    fetchPersonalCollection,
  } = useCollectionStore();

  useEffect(() => {
    fetchAllCards();
  }, [fetchAllCards]);

  useEffect(() => {
    fetchUser();
    fetchPersonalCollection();
  }, [fetchPersonalCollection, fetchUser]);

  useEffect(() => {
    fetchAllRequests();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('error') === 'unauthorized') {
      setShowWarning(true);
    }
  }, [location]);

  const handleLogin = () => {
    window.location.href = `${constants.API_BASE_URL}/auth/google`;
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${constants.API_BASE_URL}/auth/logout`, { withCredentials: true }).then(() => {
        clearUser();
        navigate('/');
      });
    } catch (error) {
      console.error('Error during logout', error);
    }
  };

  const handleMenuClick = (e) => {
    setShowMenuList(!showMenuList);
  };

  return (
      <div className="App">
        <header className="App-header">
	  <div className="header-left">
	    <div className="dropdown">
	      <button className="dropdown-button" onClick={handleMenuClick} >Menu</button>
	      <div className="dropdown-list" style={{ display: showMenuList ? 'block' : 'none' }}>
		<Link to="/personal" onClick={handleMenuClick}>User</Link>
		<Link to="/clusters" onClick={handleMenuClick}>Clusters</Link>
	      </div>
	    </div>
	    <div className="header-title">TCG Cluster</div>
	  </div>
          <div className="nav-links">
	    <Link to="/personal" className="main-link">User</Link>
	    <Link to="/clusters" className=" main-link">Clusters</Link>
          </div>
	  <div className="header-right">
		{user ? (
		  <div className="header-logout">
		    <span>{user.name}</span>
		    <button onClick={handleLogout}>Logout</button>
		  </div>
		) : (
		  <button onClick={handleLogin}>Login</button>
		)}
	      </div>
        </header>
      {showWarning && (
        <div className="warning">
          You are not allowed to register, ask for an invitation.
        </div>
      )}
        <div className="main-content">
          <Routes>
	    <Route path="/" element={<PersonalDashboard />} />
            <Route path="/personal/*" element={<PersonalDashboard />} />
            <Route path="/clusters" element={<ClustersView />} />
            <Route path="/clusters/:clusterId/*" element={<ClusterDashboard />} />
            <Route path="/clusters/join/:token" element={<JoinCluster />} />
	    <Route path="/clusters/:clusterId/events/:eventId/requests" element={<EventRequestsView />} />
          </Routes>
        </div>
      </div>
  );
}

export default DesktopApp;

