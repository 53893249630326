// Example usage in ClustersView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import constants from '../constants';
import '../css/clustersView.css'; // Import the new CSS file for ClustersView

const ClustersView = () => {
  const [clusters, setClusters] = useState([]);
  const [newClusterName, setNewClusterName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clusterToDelete, setClusterToDelete] = useState(null);
  const [clusterToEdit, setClusterToEdit] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the clusters the user is a member of
    axios.get(`${constants.API_BASE_URL}/api/clusters`, { withCredentials: true })
      .then(response => {
        setClusters(response.data);
      })
      .catch(error => {
        console.error('Error fetching clusters', error);
      });
  }, []);

  const handleCreateCluster = () => {
    axios.post(`${constants.API_BASE_URL}/api/clusters/create`, { name: newClusterName }, { withCredentials: true })
      .then(response => {
        setClusters([...clusters, response.data]);
        setNewClusterName('');
        setIsCreating(false);
      })
      .catch(error => {
        console.error('Error creating cluster', error);
      });
  };

  const handleEditNameChange = (e) => {
    e.stopPropagation();
    setClusterToEdit({ ...clusterToEdit, name: e.target.value });
  };
  
  const handleEditCluster = (clusterId, currentName) => {
    setIsEditing(true);
    setClusterToEdit({ id: clusterId, name: currentName });
  };

  const handleSaveEdit = (e) => {
    e.stopPropagation();
    axios.put(`${constants.API_BASE_URL}/api/clusters/${clusterToEdit.id}/edit`, { name: clusterToEdit.name }, { withCredentials: true })
      .then(response => {
        setClusters(clusters.map(cluster => (cluster.id === clusterToEdit.id ? response.data.cluster : cluster)));
        setIsEditing(false);
        setClusterToEdit(null);
      })
      .catch(error => {
        console.error('Error editing cluster', error);
      });
  };

  const handleSelectCluster = (clusterId) => {
    navigate(`/clusters/${clusterId}`);
  };


  const handleDeleteCluster = () => {
    if (clusterToDelete) {
      axios.delete(`${constants.API_BASE_URL}/api/clusters/${clusterToDelete}`, { withCredentials: true })
        .then(() => {
          setClusters(clusters.filter(cluster => cluster.id !== clusterToDelete));
          setShowDeleteModal(false);
          setClusterToDelete(null);
        })
        .catch(error => {
          console.error('Error deleting cluster', error);
        });
    }
  };

  const openDeleteModal = (clusterId) => {
    setClusterToDelete(clusterId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setClusterToDelete(null);
  };

  return (
    <div className="clusters-view">
      <h2>My Clusters</h2>
      <div className="clusters-grid">
        <div className="cluster-frame create-cluster" onClick={() => setIsCreating(true)}>
          {isCreating ? (
            <div className="create-cluster-form">
              <input
                type="text"
                value={newClusterName}
                onChange={e => setNewClusterName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                placeholder="Cluster name"
              />
              <button onClick={handleCreateCluster}>Create</button>
            </div>
          ) : (
            <div className="create-cluster-placeholder">+</div>
          )}
        </div>
        {clusters.map(cluster => (
          <div key={cluster.id} className="cluster-frame" onClick={() => handleSelectCluster(cluster.id)}>
            {isEditing && clusterToEdit && cluster.id === clusterToEdit.id ? (
              <div className="edit-cluster-form">
                <input
                  type="text"
                  value={clusterToEdit.name}
                  onChange={handleEditNameChange}
		  onClick={(e) => e.stopPropagation()}
                />
                <button onClick={handleSaveEdit}>Save</button>
		<button
		  onClick={(e) => {
		    e.stopPropagation();
		    setIsEditing(false);
		  }}	
		>Cancel</button>
              </div>
            ) : (
              <>
                <div 
                  className="edit-icon" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditCluster(cluster.id, cluster.name);
                  }}
                >✎</div>
                <div 
                  className="delete-icon" 
                  onClick={(e) => {
                    e.stopPropagation();
                    openDeleteModal(cluster.id);
                  }}
                >×</div>
                <Link to={`/clusters/${cluster.id}`} className="cluster-name">{cluster.name}</Link>
              </>
            )}
          </div>
        ))}
      </div>
      {showDeleteModal && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete this cluster? ALL EVENTS RELATED WILL BE REMOVED</p>
            <button className="confirm-button" onClick={handleDeleteCluster}>Yes, Delete</button>
            <button className="cancel-button" onClick={closeDeleteModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClustersView;

