import React, { useEffect, useState } from 'react';
import CollectionItem from './CollectionItem';
import NormalFoilBadges from './NormalFoilBadges';
import useCollectionStore from '../state/collectionStore';

const FilteredUserCollection = ({ filteredCollection, handleCollectionItemClick, operation, quantity }) => {
  const {
    modifyCopiesInCollection,
  } = useCollectionStore();
  const clickedMessage = `${quantity} ${operation === 'add' ? 'added' : 'removed'}`;
  const submClassName = operation === 'add' ? 'submitted-added-message' : 'submitted-removed-message';
  const onBadgeClicked = (cardId, cardNumber, set_id, isFoil, operation, quantity) => {
    modifyCopiesInCollection(cardNumber, set_id, isFoil, operation, quantity);
  }

  return (
	<div className="collection-view">
	  <ul>
	    {filteredCollection.map(item => (
            <div key={item.card_id} className="collection-item-container" style={{position: 'relative'}}>
	      <CollectionItem
	         card_id={item.card_id}
		 card_name={item.card_name}
		 card_surname={item.card_surname}
		 set_id={item.set_id}
		 card_number={item.card_number}
		 color_name={item.color_name}
		 rarity_name={item.rarity_name}
		 total_normal={item.total_normal}
		 total_foil={item.total_foil}
		 handleCollectionItemClick={handleCollectionItemClick}
	       />
	      <NormalFoilBadges
		total_normal={item.total_normal}
		total_foil={item.total_foil}
                setNormalOnClick={() => {onBadgeClicked(item.card_id, item.card_number, item.set_id, false, operation, quantity)}}
                setFoilOnClick={() => {onBadgeClicked(item.card_id, item.card_number, item.set_id, true, operation, quantity)}}
                clickedMessage={clickedMessage}
                submClassName={submClassName}
	      />
	   </div>
	    ))}
	  </ul>
	</div>
    );
};

export default FilteredUserCollection;
