// hooks/useMyLendingSummary.js
import { useEffect, useState } from 'react';
import useLendingsStore from '../state/lendingsStore';

const useMyLendingSummary = () => {
  const [isMyLendingSummaryLoading, setIsMyLendingSummaryLoading] = useState(true);
  const myLendingsSummary = useLendingsStore((state) => state.myLendingsSummary);

  useEffect(() => {
    // Fetch all requests if not already loaded
    if (!myLendingsSummary || myLendingsSummary.length === 0) {
      setIsMyLendingSummaryLoading(true);
    } else {
      setIsMyLendingSummaryLoading(false);
    }
  }, [myLendingsSummary]);

  return { isMyLendingSummaryLoading, myLendingsSummary };
};

export default useMyLendingSummary;

