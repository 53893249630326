import create from 'zustand';
import axios from 'axios';
import constants from '../constants';
import { devtools } from 'zustand/middleware';
import useUserActivityStore from './userActivityStore';

const useRequestsStore = create(
   devtools(
	(set) => ({
	  allRequests: [],
	  myRequests: [],
	  setAllRequests: (requests) => set({ allRequests: requests }),
	  setRequest: (request) => set((state) => {
	    const updatedRequests = state.allRequests.map(req => req.request_id === request.request_id ? request : req);
	    return { allRequests: updatedRequests };
	  }),
	  fetchAllRequests: async () => {
	      axios.get(`${constants.API_BASE_URL}/api/requests`, { withCredentials: true })
		.then(response => {
		   const requests = response.data.requests; 
                   requests.map(request => {
		      const input = {
			 normal_input: request.normal_lent_byuser,
			 foil_input: request.foil_lent_byuser,
		      };
		      useUserActivityStore.getState().setRequestQuantityInputs(request.request_id, input);
		   });

	           set({ allRequests: requests });
		})
		.catch(error => {
		   console.log("Error fetching all requests", error);
		});
	  },
	  fetchMyRequests: async () => {
	    try {
	      const response = await axios.get(`${constants.API_BASE_URL}/api/requests/user`, { withCredentials: true });
	      set({ myRequests: response.data.requests });
	    } catch (error) {
	      console.error('Error fetching user requests', error);
	    }
	  },
	  updateRequest: async (request_id, request) => {
	    try {
	      const response = await axios.put(`${constants.API_BASE_URL}/api/requests/${request_id}`, request, { withCredentials: true });
	      set({ allRequests: response.data.requests });
	    } catch (error) {
	      console.error('Error updating request', error);
	    }
	  },
	  deleteRequest: async (request_id) => {
	    try {
	      await axios.delete(`${constants.API_BASE_URL}/api/requests/${request_id}`, { withCredentials: true });
	      const updatedAllRequests = useRequestsStore.getState().allRequests.filter(request => request.request_id !== request_id);
	      
	      // Update myRequests
	      const updatedMyRequests = { ...useRequestsStore.getState().myRequests };
	      delete updatedMyRequests[request_id];

	      useRequestsStore.setState({ allRequests: updatedAllRequests, myRequests: updatedMyRequests });
	    } catch (error) {
	      console.error('Error deleting request', error);
	    }
	  },
	})
   )
);

export default useRequestsStore;

