import React from 'react';
import '../css/cancelModal.css'; // Ensure you have appropriate styles

const CancelModal = ({ lending, onClose, onConfirm }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Confirm Cancellation</h3>
        <p>Are you sure you want to cancel this lending?</p>
        <p>{lending.normal_lent_byuser} normal, {lending.foil_lent_byuser} foil of {lending.card_name} {lending.card_surname}</p>
        <button className="modal-button" onClick={onConfirm}>Yes, Cancel</button>
        <button className="modal-button" onClick={onClose}>No, Go Back</button>
      </div>
    </div>
  );
};

export default CancelModal;

