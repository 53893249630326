import create from 'zustand';
import axios from 'axios';
import constants from '../constants';

const useClusterStore = create((set) => ({
  eventRequests: [],
  personalCollection: [],
  personalLentQ: {},
  clusterDetails: {},
  fetchCluster: async (clusterId) => {
	try {
	  const response = await axios.get(`${constants.API_BASE_URL}/api/clusters/${clusterId}`, { withCredentials: true });
	  set({ cluster: response.data.cluster });
	} catch (error) {
	  console.error('Error fetching cluster details', error);
	}
  },
  fetchEventRequests: async (clusterId, eventId) => {
    try {
      const response = await axios.get(`${constants.API_BASE_URL}/api/clusters/${clusterId}/${eventId}/requests`, { withCredentials: true });
      set({ eventRequests: response.data.eventRequests });
    } catch (error) {
      console.error('Error fetching event requests', error);
    }
  },
  fetchPersonalCollection: async () => {
    try {
      const response = await axios.get(`${constants.API_BASE_URL}/api/collection`, { withCredentials: true });
      set({ personalCollection: response.data.collection });
    } catch (error) {
      console.error('Error fetching personal collection', error);
    }
  },
  fetchPersonalLentQ: async (clusterId, eventId) => {
    try {
      const response = await axios.get(`${constants.API_BASE_URL}/api/clusters/${clusterId}/${eventId}/userlentq`, { withCredentials: true });
      set({ personalLentQ: response.data.totalLentQ });
    } catch (error) {
      console.error('Error fetching personal lendings', error);
    }
  },
  updatePersonalLentQ: (cardId, requestId, normalQuantity, foilQuantity) => set((state) => {
    const updatedLentQ = { ...state.personalLentQ };
    const prevNormalLentQ = state.personalLentQ[cardId] ? state.personalLentQ[cardId].total_normal_lending : 0;
    const prevFoilLentQ = state.personalLentQ[cardId] ? state.personalLentQ[cardId].total_foil_lending : 0;
    const prevNormalRequestQ = state.personalLentQ[cardId] && state.personalLentQ[cardId].requests[requestId] ? state.personalLentQ[cardId].requests[requestId].normal_quantity : 0;
    const prevFoilRequestQ = state.personalLentQ[cardId] && state.personalLentQ[cardId].requests[requestId] ? state.personalLentQ[cardId].requests[requestId].foil_quantity : 0;
    if (!updatedLentQ[cardId]) {
      updatedLentQ[cardId] = { total_normal_lending: 0, total_foil_lending: 0, requests: {} };
    }
    if (!updatedLentQ[cardId].requests[requestId]) {
      updatedLentQ[cardId].requests[requestId] = { normal_quantity: 0, foil_quantity: 0 };
    }
    updatedLentQ[cardId].total_normal_lending = prevNormalLentQ - prevNormalRequestQ + normalQuantity;
    updatedLentQ[cardId].total_foil_lending = prevFoilLentQ - prevFoilRequestQ + foilQuantity;
    updatedLentQ[cardId].requests[requestId].normal_quantity = normalQuantity;
    updatedLentQ[cardId].requests[requestId].foil_quantity = foilQuantity;
    return { personalLentQ: updatedLentQ };
  }),
}));

export default useClusterStore;

