import create from 'zustand';
import constants from '../constants';
import axios from 'axios';
const useUserActivityStore = create((set) => ({
    requestQuantityInputs: {},
    setRequestQuantityInputs: (requestId, input) => set((state) => {
      const newRequestQuantityInputs = {...state.requestQuantityInputs};
      newRequestQuantityInputs[requestId] = input;
      return {requestQuantityInputs: newRequestQuantityInputs};
    }),
}));

export default useUserActivityStore;

