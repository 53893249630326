// hooks/useMyRequests.js.js
import { useEffect, useState } from 'react';
import useRequestsStore from '../state/requestsStore';

const useMyRequests = () => {
  const [isMyRequestsLoading, setIsMyRequestsLoading] = useState(true);
  const myRequests = useRequestsStore((state) => state.myRequests);

  useEffect(() => {
    // Fetch all requests if not already loaded
    if (!myRequests || myRequests.length === 0) {
      setIsMyRequestsLoading(true);
    } else {
      setIsMyRequestsLoading(false);
    }
  }, [myRequests]);

  return { isMyRequestsLoading, myRequests };
};

export default useMyRequests;

