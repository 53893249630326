import { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../constants';

const useFetchClusterData = (clusterId) => {
  const [clusterData, setClusterData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        axios.get(`${constants.API_BASE_URL}/api/clusters/${clusterId}`, { withCredentials: true })
	  .then(response => {
	    setClusterData(response.data);
	    setIsLoading(false);
	  })
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchClusterData();
  }, [clusterId]);

  const isDataDownloaded = () => {
    return clusterData !== null && !isLoading && !error;
  };

  return { clusterData, isLoading, error, isDataDownloaded };
};

export default useFetchClusterData;

