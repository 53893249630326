import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUserStore from '../state/userStore';
import useRequestsStore from '../state/requestsStore';
import MyRequestItem from './MyRequestItem';
import usePersonalCollection from '../hooks/usePersonalCollection';
import useAllRequests from '../hooks/useAllRequests';
import useMyRequests from '../hooks/useMyRequests';

import '../css/myrequestsview.css'; // Assuming you have a CSS file for styling

const MyRequestsView = () => {
  const {isPersonalCollectionLoading, personalCollection} = usePersonalCollection();
  const {isAllRequestsLoading, allRequests} = useAllRequests();
  const {isMyRequestsLoading, myRequests} = useMyRequests();
  const {
     user,
  } = useUserStore();

  const {
    fetchMyRequests,
    fetchAllRequests,
    deleteRequest,
  } = useRequestsStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get('eventId');
  const clusterId = queryParams.get('clusterId');
  const myFlatRequests = !eventId ? allRequests.filter(request => request.requester_id === user.id) 
	                           : allRequests.filter(request => request.requester_id === user.id && request.event_id == eventId);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  
  useEffect(() => {
	fetchMyRequests();
        fetchAllRequests();
  }, []);
  const handleCancelAll = () => {
    myFlatRequests.forEach(request => {
      deleteRequest(request.request_id);
    });
    setIsCancelModalVisible(false);
  };

  const handleOpenCancelModal = () => {
    setIsCancelModalVisible(true);
  };

  const handleCloseCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  if (isAllRequestsLoading || isPersonalCollectionLoading || isMyRequestsLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="lendings-view">
      <div className="my-requests-operations">
        <button className="cancel-button" onClick={handleOpenCancelModal}>Cancel All</button>
				
      </div>
      <ul>
	{myFlatRequests.map(request => {
	  const lendings = myRequests[request.request_id].lendings;
	  return (
	    <MyRequestItem key={request.request_id} request={request} lendings={lendings} />
	  );
	})}
      </ul>
      {isCancelModalVisible && (
        <div className="modal-overlay" onClick={handleCloseCancelModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Confirm Cancel All Requests</h3>
            <p>Are you sure you want to cancel all your requests?</p>
              <button className="modal-button" onClick={handleCancelAll}>Yes, Cancel All</button>
              <button className="modal-button" onClick={handleCloseCancelModal}>No, Go Back</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyRequestsView;

